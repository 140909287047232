import styled from 'styled-components';
import Collapse from 'antd/es/collapse';
import { Colors, FontSizes, FontWeights } from 'core/CssVariables';
import { Icon } from '../Icon';
import { CollapseInterface } from './Collapse.types';

const { Panel } = Collapse;

const SIcon = styled(Icon)<{ $isActive: boolean }>`
  &&&& {
    transform: rotate(${(props) => (props.$isActive ? '180deg' : 0)});
  }
`;

const SCollapse = styled<CollapseInterface>(Collapse)`
  && {
    &.ant-collapse {
      background-color: ${Colors.White};
      .ant-collapse-item {
        .ant-collapse-header {
          .ant-collapse-header-text {
            font-weight: ${FontWeights.Medium};
            font-size: ${FontSizes.FontMD}px;
            color: ${Colors.Black};
          }
        }
        .ant-collapse-content {
          border-top: 0;
          .ant-collapse-content-box {
            padding: 8px 16px 16px;
          }
        }
      }
    }
  }
`;

const SPanel = styled(Panel)`
  && {
  }
`;

export { SIcon, SCollapse, SPanel };
