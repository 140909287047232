import { Colors, Spacing } from 'core/CssVariables';
import styled from 'styled-components';

export const UploaderWrapper = styled.div`
  && {
    text-align: center;
    padding: ${Spacing.SpacingLG}px;
    position: relative;
    cursor: pointer;
    border: 1px dashed ${Colors.PrimaryColor};
    background-color: ${Colors.BackgroundColor};
    border-radius: 8px;
  }
`;
