import React, { FC, RefObject, useRef, useState } from 'react';
import { useResize } from '../../../../hooks/useResize';
import { PageBody } from '../PageBody';
import { NoResult } from '../NoResultWrapper';
import PageHeader from '../PageHeader';
import { TPageWrapperProps } from '../../PageWrapper.type';
import BackButton from '../BackButton';

const DetailsPageWrapper: FC<TPageWrapperProps> = (props) => {
  const {
    title,
    noResult,
    subTitle,
    actions,
    leftSidedActions,
    children,
    fontLevel = 2,
    backButtonTitle,
    loading,
    hideHeaderOnBreakPoint,
    noResultText,
    actionTitle,
    onActionClick,
    onBackClick,
    footerHeight,
  } = props;
  const [headerHeight, setHeaderHeight] = useState(props.headerHeight || 0);
  const headerRef = useRef<HTMLDivElement>(null);

  const onResize = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current && !props.headerHeight) {
      setHeaderHeight(ref.current.clientHeight);
    }
  };
  useResize<HTMLDivElement>(headerRef, onResize);

  return (
    <>
      <div ref={headerRef}>
        <BackButton title={backButtonTitle} onClick={onBackClick} />
        {!loading && (
          <PageHeader
            actionTitle={actionTitle}
            onActionClick={onActionClick}
            title={noResult ? '' : title}
            subTitle={subTitle}
            actions={!noResult && actions}
            leftSidedActions={!noResult && leftSidedActions}
            fontLevel={fontLevel}
            hideHeaderOnBreakPoint={hideHeaderOnBreakPoint}
          />
        )}
      </div>
      {noResult ? (
        <NoResult img="" noResult={noResult} text={noResultText} />
      ) : (
        <PageBody
          loading={loading}
          headerHeight={headerHeight}
          footerHeight={footerHeight}
        >
          {children}
        </PageBody>
      )}
    </>
  );
};

export default DetailsPageWrapper;
