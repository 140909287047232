import React, { FC } from 'react';
import { TLevel } from 'types/Font.types';
import { Colors, FontWeights } from 'core/CssVariables';
import { Text } from '../../Typography';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';

import SEditableText from '../EditableText/EditableText.style';

type EditableTextWithoutPermissionProps = {
  icon?: TIconNames;
  value?: string;
  color?: Colors;
  fontWeight?: FontWeights;
  fontLevel?: TLevel;
  className?: string;
};

const NonEditableText: FC<EditableTextWithoutPermissionProps> = (props) => {
  const { value, icon, fontWeight, fontLevel, color, className = '' } = props;

  if (!value) {
    return null;
  }
  return (
    <SEditableText
      className={`my-2 ${className}`}
      canEdit={false}
      color={color || Colors.DarkGrey}
    >
      {icon && (
        <span>
          <Icon
            color={color || Colors.DarkGrey}
            icon={icon}
            size={16}
            className="mr-2"
          />
        </span>
      )}
      <Text
        fontLevel={fontLevel || 5}
        fontWeight={fontWeight || FontWeights.Medium}
      >
        {value}
      </Text>
    </SEditableText>
  );
};

export { NonEditableText };
