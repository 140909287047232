import React, { FC } from 'react';
import Statistic from 'antd/es/statistic';
import { TCountdownProps } from './Countdown.type';

const { Countdown: AntdCountdown } = Statistic;

export const Countdown: FC<TCountdownProps> = ({ sec, ...props }) => {
  return <AntdCountdown value={Date.now() + 1000 * sec} {...props} />;
};

export default Countdown;
