import React, { FC, useEffect, useState } from 'react';
import { EmptyText } from '../EmptyText';
import TextView from './components/TextView';
import EditableTextAreaForm from './components/EditableTextAreaForm';
import { SNonEditableText } from './EditableTextArea.style';
import { TEditableTextAreaProps } from '../EditableFields.type';
import EditableFieldWrapper from '../EditableFieldWrapper';

export const EditableTextArea: FC<TEditableTextAreaProps> = (props) => {
  const {
    fontLevel,
    onEdit,
    isLoading,
    selected,
    text,
    error,
    icon,
    minMedia,
    emptyText,
    fontWeight,
    color,
    canRemove,
    canEdit,
    hasTooltip = false,
    placeholder,
    required,
  } = props;
  const [value, setValue] = useState(text);
  const [isEditable, setIsEditable] = useState(false);

  const onChange = (newValue: string) => {
    setIsEditable(false);
    if (!canRemove && !newValue) {
      setValue(text);
      return;
    }
    if (text !== newValue || error) {
      setValue(newValue);
      if (onEdit) {
        onEdit(newValue);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setValue(text);
    }
  }, [error]);

  useEffect(() => {
    setValue(text);
  }, [text]);

  if (canEdit) {
    return (
      <EditableFieldWrapper
        canEdit={canEdit}
        isEditable={isEditable}
        selected={selected}
        icon={icon}
        color={color}
        minMedia={minMedia}
        isLoading={isLoading}
        error={hasTooltip ? error : ''}
      >
        {isEditable ? (
          <EditableTextAreaForm
            onEdit={onChange}
            placeholder={placeholder}
            setIsEditable={setIsEditable}
            setValue={setValue}
            required={required}
            text={text}
          />
        ) : (
          <>
            {value ? (
              <TextView
                setIsEditable={setIsEditable}
                text={value}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
              />
            ) : (
              <EmptyText
                emptyText={emptyText}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
                onClick={() => setIsEditable(true)}
              />
            )}
          </>
        )}
      </EditableFieldWrapper>
    );
  }

  return (
    <SNonEditableText
      value={value}
      icon={icon}
      fontWeight={fontWeight}
      fontLevel={fontLevel}
      color={color}
    />
  );
};

export default EditableTextArea;
