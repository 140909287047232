import React from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Text } from '../../../Typography';
import { Icon } from '../../../Icon';
import { TIconNames } from '../../../Icon/Icon.type';
import { TEditableSelectTextProps } from '../../EditableFields.type';

const EditableSelectText: React.FC<TEditableSelectTextProps> = ({
  setIsEditable,
  fontWeight,
  fontLevel,
  value,
  color,
  showTextValue,
}) => {
  const onHandleClick = () => {
    setIsEditable(true);
  };
  return (
    <Text
      className="pt-1 pb-1 pl-2"
      fontLevel={fontLevel || 5}
      fontWeight={fontWeight || FontWeights.Medium}
      onClick={onHandleClick}
      {...(color && { color })}
      editable={{
        icon: (
          <Icon
            icon={TIconNames.EDIT}
            color={Colors.Blue}
            size={16}
            className="edit-icon ml-2"
          />
        ),
      }}
    >
      {showTextValue || value?.label}
    </Text>
  );
};

export default EditableSelectText;
