import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import EmptyConent from './EmptyConent';
import { useGetMenuItems } from '../MobileSelect.hook';
import { SMenu, SSearchWrapper } from '../../../Select.style';
import { TSelectMenuProps } from '../../../Select.type';
import { TextField } from '../../../../TextField';

const SelectMenu = ({
  isSearchable,
  isCreatable,
  options,
  placeholder = 'Select...',
  ...props
}: TSelectMenuProps) => {
  const { menuItems, handleSearch, selectedKeys } = useGetMenuItems({
    isSearchable,
    isCreatable,
    options,
    ...props,
  });
  const [menuIndex] = useState(Date.now());

  return (
    <>
      {(isSearchable || isCreatable) && (
        <SSearchWrapper>
          <TextField
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={placeholder}
          />
        </SSearchWrapper>
      )}
      {isEmpty(menuItems) ? (
        <EmptyConent />
      ) : (
        <SMenu key={menuIndex} selectedKeys={selectedKeys} items={menuItems} />
      )}
    </>
  );
};

export default SelectMenu;
