import styled from 'styled-components';
import { Screens } from 'core/CssVariables';
import { Col } from '../../../Grid';

const SPageBody = styled(Col)<{
  $headerHeight?: number;
  $footerHeight?: number;
}>`
  && {
    height: ${({ $headerHeight, $footerHeight }) =>
      $headerHeight !== undefined
        ? `calc(100vh - ${$headerHeight + ($footerHeight || 0)}px)`
        : 'auto'};
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: ${Screens.ScreensMD}) {
      height: ${({ $headerHeight }) =>
        $headerHeight !== undefined
          ? `calc(100vh - ${$headerHeight + 148}px)`
          : 'auto'};
    }
  }
`;

const SPageSpinner = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
`;
export { SPageBody, SPageSpinner };
