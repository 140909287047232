import { TFile } from '../../types/generalTypes';

export type TImageUploaderProps = {
  id?: string;
  url?: string | null;
  width?: number;
  height?: number;
  disabled?: boolean;
  isLoading?: boolean;
  uploadedImage?: TFile;
  onChange?: (data: TFile) => void;
  uploadFile?: (formData: FormData) => void;
};

export enum EErrorMessage {
  SIZE_ERROR = 'Please upload images up to 10MB.',
  FORMAT_ERROR = 'Please upload JPEG or PNG files.',
}
