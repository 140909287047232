import { Screens } from 'core/CssVariables';
import styled from 'styled-components';
import { TScrollableContainerStyledProps } from './ScrollableContainer.type';
import { Col } from '../Grid';

export const SScrollableContainer = styled(
  Col
)<TScrollableContainerStyledProps>`
  && {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: ${({ $maxHeight }) => $maxHeight || ''};
    padding-right: 8px;

    @media (max-width: ${Screens.ScreensMD}) {
      padding-right: 0;
    }
  }
`;
