import { useEffect, useState } from 'react';
import get from 'lodash/get';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import {
  TSelectOptionTypeUi,
  TGetMenuItemsHookProps,
  TSlecetMenuItem,
} from '../../Select.type';

const getValue = (option: TSelectOptionTypeUi, keyPath?: string[]): string =>
  isObject(option?.value) ? get(option?.value, keyPath || []) : option?.value;

export const useGetMenuItems = ({
  options,
  selectedOption,
  keyPath,
  onSelect,
  isMulti,
  isCreatable,
  isSearchable,
}: TGetMenuItemsHookProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [originalMenuItems, setOriginalMenuItems] = useState<TSlecetMenuItem[]>(
    []
  );
  const [menuItems, setMenuItems] = useState<TSlecetMenuItem[]>([]);

  const getActiveKeys = () => {
    if (!selectedOption) {
      return [];
    }

    if (isArray(selectedOption)) {
      return selectedOption
        .map((option) => getValue(option, keyPath))
        .filter(Boolean);
    }

    return [getValue(selectedOption, keyPath)];
  };

  const handleSearch = (searchValue: string) => {
    if (!searchValue) {
      setMenuItems(originalMenuItems);
      return;
    }
    const filteredMenuItem = originalMenuItems.filter(
      (item) =>
        item.label.includes(searchValue) || item.key.includes(searchValue)
    );

    if (isCreatable) {
      const newFilterItems = filteredMenuItem || [];

      if (
        !some(filteredMenuItem, ['label', searchValue]) &&
        !some(filteredMenuItem, ['key', searchValue])
      ) {
        newFilterItems.unshift({
          label: `Create "${searchValue}"`,
          onClick: () =>
            onSelect({
              __isNew__: true,
              value: searchValue,
              label: searchValue,
            }),
          key: searchValue,
        });
      }
      setMenuItems(newFilterItems);
      return;
    }
    setMenuItems(filteredMenuItem);
  };

  useEffect(() => {
    setSelectedKeys(getActiveKeys());
  }, [selectedOption, options]);

  useEffect(() => {
    if (isEmpty(options)) {
      return;
    }
    const menuItems = options.map((option) => ({
      label: option.label,
      onClick: () => {
        onSelect(option);
        const clickedKey = getValue(option, keyPath);

        if (isMulti) {
          if (selectedKeys.includes(clickedKey)) {
            setSelectedKeys(selectedKeys.filter((item) => item !== clickedKey));
            onSelect(option, true);
            return;
          }

          setSelectedKeys(uniq([...selectedKeys, clickedKey]));
          return;
        }
        setSelectedKeys([clickedKey]);
      },
      key: getValue(option, keyPath),
    }));

    setOriginalMenuItems(menuItems);
    setMenuItems(menuItems);
  }, [options, selectedKeys]);

  return {
    selectedKeys,
    menuItems,
    handleSearch,
  };
};
