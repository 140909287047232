import { Radio, RadioProps } from 'antd';
import { Colors } from 'core/CssVariables';
import styled, { css } from 'styled-components';

const mainRadioGroupSwitcherStyles = ({ disabled }: RadioProps) => css`
  &.ant-radio-group {
    display: flex;
    width: fit-content;
    background-color: ${Colors.White};
    border: 1px solid ${Colors.DisabledColor};
    border-radius: 20px;
    .ant-radio-button-wrapper {
      border: none;
      min-width: 96px;
      height: 28px;
      line-height: 28px;
      background-color: ${Colors.White};
      svg {
        path {
          fill: ${disabled ? Colors.LightGrey : Colors.Black};
        }
      }
      ::before {
        background-color: ${Colors.DisabledColor};
      }
      &.ant-radio-button-wrapper-checked {
        background-color: ${disabled
          ? Colors.DisabledColor
          : Colors.PrimaryColor};
        border-radius: 20px 0 0 20px;
      }
      :first-child {
        border-radius: 20px 0 0 20px;
      }
      :last-child {
        border-radius: 0 20px 20px 0;
      }
      .ant-typography {
        word-break: keep-all !important;
        color: ${disabled ? Colors.LightGrey : Colors.Black};
      }
    }
  }
`;

const SRadioGroup = styled(Radio.Group)<RadioProps>`
  ${props => mainRadioGroupSwitcherStyles(props)};
`;

export { SRadioGroup };
