import styled from 'styled-components';
import { Colors } from 'core/CssVariables';
import { Text } from '../Typography';

export const SText = styled(Text)`
  display: inline-block;

  a {
    white-space: nowrap;

    &,
    :hover {
      color: ${Colors.DarkBlue};
      text-decoration: underline;
    }

    :visited {
      color: ${Colors.Blue};
    }
  }
`;
