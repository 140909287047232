import React, { FC } from 'react';
import { Row, Col } from '../Grid';
import { Text } from '../Typography';
import { Colors, FontWeights } from 'core/CssVariables';
import { TSwitch } from './Switch.types';
import SSwitch from './Switch.styles';

const Switch: FC<TSwitch> = ({ label, ...props }) => {
  return (
    <Row gutter={[12, 0]}>
      <Col>
        <Text fontWeight={FontWeights.Medium} color={Colors.Black}>
          {label}
        </Text>
      </Col>
      <Col>
        <SSwitch {...props} />
      </Col>
    </Row>
  );
};

export { Switch };
