import React from 'react';
import { TLevel } from 'types/Font.types';
import { Colors, FontWeights } from 'core/CssVariables';
import { Text } from '../../Typography';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';

type TEmptyTextProps = {
  onClick: () => void;
  fontWeight?: FontWeights;
  fontLevel?: TLevel;
  emptyText: string | React.ReactElement;
};
const EmptyText: React.FC<TEmptyTextProps> = ({
  onClick,
  fontWeight,
  fontLevel,
  emptyText,
}) => {
  return (
    <Text
      className="pointer pt-1 pb-1 pl-2"
      fontLevel={fontLevel || 5}
      fontWeight={fontWeight || FontWeights.Regular}
      onClick={onClick}
      editable={{
        icon: (
          <Icon
            icon={TIconNames.EDIT}
            color={Colors.Blue}
            size={20}
            className="edit-icon ml-2"
          />
        ),
      }}
    >
      {emptyText}
    </Text>
  );
};
export { EmptyText };
