import React, { FC, useEffect, useState } from 'react';
import { EmptyText } from '../EmptyText';
import { NonEditableText } from '../NonEditableText';
import EditableFieldWrapper from '../EditableFieldWrapper';
import TextEmail from './components/TextEmail';
import EditableEmailForm from './components/EditableEmailForm';
import { TEditableEmailProps } from '../EditableFields.type';

export const EditableEmail: FC<TEditableEmailProps> = (props) => {
  const {
    fontLevel,
    onEdit,
    isLoading,
    selected,
    email,
    error,
    icon,
    minMedia,
    fontWeight,
    placeholder,
    required,
    emptyText,
    color,
    canEdit = true,
    canRemove = true,
    hasTooltip = false,
  } = props;
  const [value, setValue] = useState(email);
  const [isEditable, setIsEditable] = useState(false);

  const onChange = (newValue: string) => {
    setIsEditable(false);
    if (!canRemove && !newValue) {
      setValue(email);
      return;
    }
    if (email !== newValue || error) {
      setValue(newValue);
      onEdit(newValue);
    }
  };

  useEffect(() => {
    if (error) {
      setValue(email);
    }
  }, [error]);

  useEffect(() => {
    setValue(email);
  }, [email]);

  if (canEdit) {
    return (
      <EditableFieldWrapper
        canEdit={canEdit}
        isEditable={isEditable}
        selected={selected}
        icon={icon}
        color={color}
        minMedia={minMedia}
        isLoading={isLoading}
        error={hasTooltip ? error : ''}
      >
        {isEditable ? (
          <EditableEmailForm
            onEdit={onChange}
            placeholder={placeholder}
            setIsEditable={setIsEditable}
            setValue={setValue}
            email={email}
            required={required}
          />
        ) : (
          <>
            {value ? (
              <TextEmail
                setIsEditable={setIsEditable}
                email={value}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
              />
            ) : (
              <EmptyText
                emptyText={emptyText}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
                onClick={() => setIsEditable(true)}
              />
            )}
          </>
        )}
      </EditableFieldWrapper>
    );
  }
  return (
    <NonEditableText
      value={value}
      icon={icon}
      fontWeight={fontWeight}
      fontLevel={fontLevel}
    />
  );
};

export default EditableEmail;
