import styled, { css } from 'styled-components';
import { Colors } from 'core/CssVariables';
import Avatar from 'antd/es/avatar';
import { CompoundedComponent } from './Avatar.type';

export const SAvatar = styled<CompoundedComponent>(Avatar)`
  && {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.PrimaryColor};
    color: ${Colors.Black};

    .icon {
      path {
        fill: ${Colors.Black};
      }
    }

    ${({ $active }) =>
      $active &&
      css`
        background-color: ${Colors.Black};
        border-color: ${Colors.Black};
        color: ${Colors.White};

        .icon {
          path {
            fill: ${Colors.White};
          }
        }
      `}
  }
`;
