import React, { FC } from 'react';
import { TSelectComponentProps } from '../Select.type';
import { SCreatableSelect, SSelect } from '../Select.style';
import MultiValueRemove from './MultiValueRemove';
import ClearIndicator from './ClearIndicator';
import DropdownIndicator from './DropdownIndicator';

const DesktopSelect: FC<TSelectComponentProps> = (props) => {
  const { onChange, options, isCreatable, ...other } = props;

  return isCreatable ? (
    <SCreatableSelect
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      hideSelectedOptions={false}
      options={options}
      onChange={onChange}
      {...other}
    />
  ) : (
    <SSelect
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      hideSelectedOptions={false}
      options={options}
      onChange={onChange}
      {...other}
    />
  );
};

export default DesktopSelect;
