import styled from 'styled-components';
import Badge, { BadgeProps } from 'antd/es/badge';
import Ribbon from 'antd/es/badge/Ribbon';

interface CompoundedComponent extends React.FC<BadgeProps> {
  Ribbon: typeof Ribbon;
}

export const SBadge = styled<CompoundedComponent>(Badge)`
  && {
    .ant-badge-dot {
      width: 8px;
      height: 8px;
      top: 1px;
      right: -4px;
    }
  }
`;
