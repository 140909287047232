import styled from 'styled-components';
import { Screens } from 'core/CssVariables';

export const SVerificationInputContainer = styled.div`
  &&& {
    display: flex;
    justify-content: center;

    .verification-input {
      height: 46px;
      width: 40px;
      text-align: center;

      :not(:last-child) {
        margin-right: 16px;

        @media (max-width: ${Screens.ScreensXS}) {
          margin-right: 8px;
        }
      }
    }
  }
`;
