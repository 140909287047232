import Menu from 'antd/es/menu';
import styled from 'styled-components';
import { TMenuItemProps, TMenuProps } from './Menu.types';

const { Item } = Menu;

const SMenu = styled<any>(Menu)<TMenuProps>``;

const SMenuItem = styled<any>(Item)<TMenuItemProps>``;

export { SMenu, SMenuItem };
