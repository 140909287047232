import { TThemeProps } from 'theme/theme.type';
import styled, { ThemedStyledProps } from 'styled-components';
import { Row } from '../../../Grid';
import { RowProps } from '../../../Grid/Grid.type';

export const SPageHeader = styled(Row)<
  ThemedStyledProps<RowProps, TThemeProps>
>`
  && {
    margin: 12px 0 24px;

    .page-title {
      color: ${(props) => props.theme.color};
      line-height: 28.7px;
    }

    .content {
      overflow: hidden;
      margin-bottom: 24px;
    }
  }
`;

export default SPageHeader;
