import React, { FC, RefObject, useRef, useState } from 'react';
import { useResize } from '../../../../hooks/useResize';
import { TPageWrapperProps } from '../../PageWrapper.type';
import PageHeader from '../PageHeader';
import { PageBody } from '../PageBody';

const MainPageWrapper: FC<TPageWrapperProps> = (props) => {
  const {
    title,
    noResult,
    subTitle,
    actions,
    actionTitle,
    children,
    fontLevel = 2,
    onActionClick,
    loading,
    hideActions,
    hideHeaderOnBreakPoint,
    withoutScroll,
    footerHeight,
  } = props;
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  const onResize = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      setHeaderHeight(ref.current.clientHeight);
    }
  };
  useResize<HTMLDivElement>(withoutScroll ? null : headerRef, onResize);

  return (
    <>
      <div ref={headerRef}>
        <PageHeader
          title={title}
          subTitle={subTitle}
          actions={actions}
          fontLevel={fontLevel}
          hideHeaderOnBreakPoint={hideHeaderOnBreakPoint}
          hideActions={hideActions}
          actionTitle={actionTitle}
          onActionClick={onActionClick}
        />
      </div>
      <PageBody
        {...(!withoutScroll && { headerHeight, footerHeight })}
        loading={loading}
        noResult={noResult}
      >
        {children}
      </PageBody>
    </>
  );
};

export default MainPageWrapper;
