import styled, { css } from 'styled-components';
import { Colors } from 'core/CssVariables';
import { CardSection } from '../../../CardSection';

export const SCardTabDesktop = styled(CardSection)`
  && {
    padding-top: 32px;
    width: 250px;

    ${({ active }) =>
      active &&
      css`
        .ant-avatar {
          background-color: ${Colors.Black};
        }

        .icon {
          path {
            fill: ${Colors.White};
          }
        }
      `}
    .tag {
      position: absolute;
      top: 0;
      left: 12px;
    }
    .title {
      white-space: pre-wrap;
    }
  }
`;

export default SCardTabDesktop;
