import React from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { TIconNames } from '../../Icon/Icon.type';
import { SRow } from './EditableTextWithModal.style';
import { TEditableTextWithModalProps } from '../EditableFields.type';

export const EditableTextWithModal = ({
  label,
  canEdit,
  onEdit,
  icon,
}: TEditableTextWithModalProps) => {
  return (
    <SRow
      align="middle"
      className={`my-2 ${canEdit ? 'pointer' : ''}`}
      onClick={() => canEdit && onEdit()}
      wrap={false}
    >
      {icon && (
        <Icon icon={icon} color={Colors.DarkGrey} size={16} className="mr-2" />
      )}
      <Text
        fontWeight={FontWeights.Medium}
        color={Colors.DarkGrey}
        fontLevel={5}
      >
        {label}
      </Text>
      {canEdit && (
        <Icon
          icon={TIconNames.EDIT}
          color={Colors.Blue}
          size={16}
          className="ml-2 pointer edit-icon"
        />
      )}
    </SRow>
  );
};

export default EditableTextWithModal;
