import React, { useRef } from 'react';
import { Icon } from '../../../Icon';
import { TIconNames } from '../../../Icon/Icon.type';
import ReactPlayer from 'react-player';
import { Colors } from 'core/CssVariables';
import { SVideoItemWrapper } from './VideoItem.style';
import { TUploadedFileProps } from '../../Uploader.type';

const VideoItem = ({ file, onDelete, readOnly }: TUploadedFileProps) => {
  const ref = useRef<ReactPlayer>(null);
  return (
    <SVideoItemWrapper>
      <ReactPlayer
        ref={ref}
        url={file.url}
        width={203}
        height={119}
        playing={false}
        controls
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        playIcon={
          <Icon
            onClick={() => ref.current?.seekTo(0)}
            icon={TIconNames.PLAY_VIDEO}
            color={Colors.White}
            size={28}
          />
        }
      />
      {!readOnly && (
        <Icon
          icon={TIconNames.CLOSE_CIRCLE}
          size={16}
          className="close-icon pointer"
          onClick={onDelete}
        />
      )}
    </SVideoItemWrapper>
  );
};

export { VideoItem };
