import React from 'react';
import { components, MultiValueRemoveProps } from 'react-select';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';

const MultiValueRemove = (multiValueRemoveProps: MultiValueRemoveProps) => {
  const { MultiValueRemove: SelectMultiValueRemove } = components;

  return (
    <SelectMultiValueRemove {...multiValueRemoveProps}>
      <Icon icon={TIconNames.HIGHLIGHT_OFF} size={16} />
    </SelectMultiValueRemove>
  );
};

export default MultiValueRemove;
