import React, { FC } from 'react';
import { TPageWrapperProps } from './PageWrapper.type';
import MainPageWrapper from './components/PageWrapper/MainPageWrapper';
import DetailsPageWrapper from './components/PageWrapper/DetailsPageWrapper';
import { SPageWrapper } from './PageWrapper.style';

const PageWrapper: FC<TPageWrapperProps> = ({
  pageType = 'main',
  className,
  ...props
}) => {
  return (
    <SPageWrapper className={className}>
      {pageType === 'main' && <MainPageWrapper {...props} />}
      {pageType === 'details' && <DetailsPageWrapper {...props} />}
    </SPageWrapper>
  );
};

export { PageWrapper };
