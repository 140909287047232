import React from 'react';
import type { ModalProps } from 'antd/es/modal';

export enum ModalWidthEnum {
  XSmall = 271,
  Small = 406,
  Medium = 636,
  Large = 828,
}
export type TModalProps = ModalProps & {
  onClose?: () => void;
  size: ModalWidthEnum;
};
export type TModalDeleteProps = TModalProps & {
  warningText?: string;
  subtitleText?: string;
};
export type TModalEvent =
  | ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
  | undefined;
