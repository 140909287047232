import React from 'react';
import { Select } from '../../../Select';
import { TOnChangeParam } from '../../../Select/Select.type';
import {
  TEditableSelectFormProps,
  TOptionTypeUi,
} from '../../EditableFields.type';

const EditableSelectForm: React.FC<TEditableSelectFormProps> = ({
  placeholder,
  setIsEditable,
  onEdit,
  options,
  isCreatable,
  onMultiEdit,
  isMulti,
  value,
  isClearable,
  isMobile = false,
  keyPath = [],
  ...props
}) => {
  const handleCustomField: TOnChangeParam = (newValue: any) => {
    if (newValue) {
      if (isMulti && onMultiEdit) {
        onMultiEdit(newValue as TOptionTypeUi[]);
      } else if (onEdit) {
        onEdit(newValue as TOptionTypeUi);
        setIsEditable(false);
      }
    }
  };
  return (
    <Select
      keyPath={keyPath}
      isMobile={isMobile}
      isClearable={isClearable}
      value={value}
      isCreatable={isCreatable}
      autoFocus
      onChange={handleCustomField}
      placeholder={placeholder}
      options={options}
      isSearchable
      onBlur={() => !isMobile && setIsEditable(false)}
      className="w-100"
      {...props}
    />
  );
};

export default EditableSelectForm;
