import Dropdown from 'antd/es/dropdown';
import DropdownButton from 'antd/lib/dropdown/dropdown-button';
import styled from 'styled-components';
import { TDropdownProps } from './Dropdown.type';

interface DropdownInterface extends React.FC<TDropdownProps> {
  Button: typeof DropdownButton;
}

const SDropdown = styled<DropdownInterface>(Dropdown)<TDropdownProps>``;

export { SDropdown };
