import { Colors } from 'core/CssVariables';
import { Timeline } from 'antd';
import styled from 'styled-components';
import { ITimeline } from './Timeline.types';

export const STimeline = styled<ITimeline>(Timeline)``;

export const STimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-tail {
    border-left: 1px solid ${Colors.DisabledColor};
    top: 30px;
    height: calc(100% - 50px);
  }
  .ant-timeline-item-head {
    background-color: ${Colors.Transparent};
  }
  .ant-timeline-item-content {
    margin: 0 0 0 34px;
  }
`;
