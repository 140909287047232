import { Colors } from 'core/CssVariables';
import { TThemeProps } from './theme.type';

export const DarkTheme: TThemeProps = {
  avatar: {
    default: {
      color: Colors.BackgroundColor,
      backgroundColor: Colors.DarkGrey,
    },
  },
  card: {
    secondary: {
      backgroundColor: Colors.DarkGrey,
    },
    primary: {
      backgroundColor: Colors.DarkGrey,
    },
    default: {
      backgroundColor: Colors.DarkGrey,
      border: Colors.DarkGrey,
    },
  },
  paragraph: {
    color: Colors.Black,
  },
  text: {
    color: Colors.Black,
  },
  editableText: {
    color: Colors.DarkGrey,
    spinner: Colors.White,
  },
  scrollbar: {
    track: Colors.Black,
    thumb: Colors.DarkGrey,
  },
  color: Colors.White,
  secondaryColor: Colors.LightGrey,
  backButton: Colors.BackgroundColor,
  switch: {
    handle: Colors.DarkGrey,
    themeSwitch: {
      backgroundColor: Colors.PrimaryColor,
      lightModeColor: Colors.White,
      darkModeColor: Colors.PrimaryColor,
    },
  },
  backgroundColor: Colors.DarkGrey,
  secondaryBackgroundColor: Colors.DarkGrey,
  radio: {
    label: Colors.LightGrey,
    backgroundColor: Colors.DarkGrey,
  },
  input: {
    backgroundColor: Colors.DarkGrey,
    placeholder: Colors.BackgroundColor,
    disabledPlaceholder: Colors.DisabledColor,
    textColorPrimary: Colors.LightGrey,
    textColorDisabled: Colors.LightGrey,
    borderColor: Colors.DarkGrey,
    borderColorDisabled: Colors.LightGrey,
    errorBorderColor: Colors.ErrorColor,
    missingBorderColor: Colors.WarningColor,
  },
  sidebar: {
    backgroundColor: Colors.DarkGrey,
    menuBackgroundColor: Colors.DarkGrey,
    color: Colors.White,
  },
  table: {
    backgroundColor: Colors.DarkGrey,
    borderColor: Colors.DarkGrey,
    hoverTextColor: Colors.DarkGrey,
    sortersColor: Colors.PrimaryColor,
    pagesizeBackgroundColor: Colors.DarkGrey,
    hoverRowBackgroundColor: Colors.Black,
    checkboxBorderColor: Colors.LightGrey,
    checkboxLabelColor: Colors.LightGrey,
    filterBackgroundColor: Colors.DarkGrey,
    textColor: Colors.White,
  },
  datePicker: {
    backgroundColor: Colors.DarkGrey,
    borderColor: Colors.DarkGrey,
    disabledBackgroundColor: 'rgba(255, 255, 255, 0.06)',
  },
  timeEntryTooltip: {
    backgroundColor: Colors.DarkGrey,
  },
  timeEntryDetails: {
    item: {
      color: Colors.White,
    },
  },
  modal: {
    backgroundColor: Colors.DarkGrey,
  },
  dropdown: {
    backgroundColor: Colors.DarkGrey,
    selectedItemColor: Colors.PrimaryColor,
    selectedItemHoverColor: Colors.PrimaryColorHover,
    borderColor: Colors.DarkGrey,
    boxShadowColor: 'rgba(0, 0, 0, 0.3)',
  },
  tooltip: {
    backgroundColor: Colors.BackgroundColor,
    color: Colors.BackgroundColor,
  },
  icon: {
    color: Colors.DarkGrey,
  },
  normalWrapper: {
    backgroundColor: Colors.DarkGrey,
  },
  noResult: {
    color: Colors.LightGrey,
  },
  select: {
    color: Colors.LightGrey,
  },
  selectableText: {
    backgroundColor: Colors.DarkGrey,
  },
  profileMenu: {
    color: Colors.LightGrey,
  },
  weekDays: {
    backgroundColor: Colors.DarkGrey,
  },
  uploadButton: {
    borderColor: Colors.PrimaryColor,
    iconColor: Colors.DarkGrey,
  },
};

export const LightTheme: TThemeProps = {
  avatar: {
    default: {
      color: Colors.LightGrey,
      backgroundColor: Colors.BackgroundColor,
    },
  },
  card: {
    secondary: {
      backgroundColor: Colors.BackgroundColor,
    },
    primary: {
      backgroundColor: Colors.White,
    },
    default: {
      backgroundColor: 'transparent',
      border: Colors.BackgroundColor,
    },
  },
  paragraph: {
    color: Colors.Black,
  },
  text: {
    color: Colors.Black,
  },
  editableText: {
    spinner: Colors.PrimaryColor,
    color: Colors.DarkGrey,
  },
  scrollbar: {
    thumb: Colors.LightGrey,
    track: Colors.BackgroundColor,
  },
  color: Colors.Black,
  secondaryColor: Colors.DarkGrey,
  backButton: Colors.DarkGrey,
  switch: {
    handle: Colors.White,
    themeSwitch: {
      backgroundColor: Colors.PrimaryColor,
      lightModeColor: Colors.PrimaryColor,
      darkModeColor: Colors.DarkGrey,
    },
  },
  backgroundColor: Colors.White,
  secondaryBackgroundColor: Colors.BackgroundColor,
  radio: {
    label: Colors.DarkGrey,
    backgroundColor: Colors.White,
  },
  input: {
    backgroundColor: Colors.White,
    placeholder: Colors.LightGrey,
    disabledPlaceholder: Colors.DisabledColor,
    textColorPrimary: Colors.Black,
    textColorDisabled: Colors.DisabledColor,
    borderColor: Colors.PrimaryColor,
    borderColorDisabled: Colors.LightGrey,
    errorBorderColor: Colors.ErrorColor,
    missingBorderColor: Colors.WarningColor,
  },
  sidebar: {
    backgroundColor: Colors.White,
    menuBackgroundColor: Colors.BackgroundColor,
    color: Colors.DarkGrey,
  },
  table: {
    backgroundColor: Colors.White,
    borderColor: Colors.BackgroundColor,
    hoverTextColor: Colors.DarkGrey,
    sortersColor: Colors.PrimaryColor,
    pagesizeBackgroundColor: Colors.BackgroundColor,
    hoverRowBackgroundColor: Colors.BackgroundColor,
    checkboxBorderColor: Colors.LightGrey,
    checkboxLabelColor: Colors.Black,
    filterBackgroundColor: Colors.White,
    textColor: Colors.Black,
  },
  datePicker: {
    backgroundColor: Colors.BackgroundColor,
    borderColor: Colors.BackgroundColor,
    disabledBackgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  timeEntryTooltip: {
    backgroundColor: Colors.White,
  },
  timeEntryDetails: {
    item: {
      color: Colors.BackgroundColor,
    },
  },
  modal: {
    backgroundColor: Colors.White,
  },
  dropdown: {
    backgroundColor: Colors.White,
    selectedItemColor: Colors.PrimaryColor,
    selectedItemHoverColor: Colors.PrimaryColorHover,
    borderColor: Colors.BackgroundColor,
    boxShadowColor: 'rgba(81, 81, 81, 0.12)',
  },
  tooltip: {
    backgroundColor: Colors.DarkGrey,
    color: Colors.White,
  },
  icon: {
    color: Colors.LightGrey,
  },
  normalWrapper: {
    backgroundColor: Colors.BackgroundColor,
  },
  noResult: {
    color: Colors.BackgroundColor,
  },
  select: {
    color: Colors.Black,
  },
  selectableText: {
    backgroundColor: Colors.BackgroundColor,
  },
  profileMenu: {
    color: Colors.BackgroundColor,
  },
  weekDays: {
    backgroundColor: Colors.BackgroundColor,
  },
  uploadButton: {
    borderColor: Colors.PrimaryColor,
    iconColor: Colors.DarkGrey,
  },
};
