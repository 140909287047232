import styled from 'styled-components';
import { Screens } from 'core/CssVariables';

const SPageWrapper = styled.div<{
  hideHeaderOnBreakPoint?: Screens | false;
  width?: string;
}>`
  && {
    margin: auto;
    padding: 24px 0;
    width: ${({ width }) => width || '100%'};
    max-width: 100%;
  }
`;

export { SPageWrapper };
