import { FC } from 'react';
import styled from 'styled-components';
import Alert, { AlertProps } from 'antd/es/alert';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { BorderRadius, Colors, FontSizes } from 'core/CssVariables';
import { TAlertProps } from './Alert.type';

const textColors = {
  success: Colors.SuccessColor,
  error: Colors.ErrorColor,
  warning: Colors.WarningColor,
  info: Colors.Blue,
};

interface AlertInterface extends FC<AlertProps> {
  ErrorBoundary: typeof ErrorBoundary;
}

export const SAlert = styled<AlertInterface>(Alert)`
  && {
    margin-bottom: 24px;
    border-radius: ${BorderRadius.RadiusXS}px;
    border: 0;
    padding: 8px 12px;
    font-size: ${FontSizes.FontSM}px;

    .ant-alert-message {
      color: ${(props: TAlertProps) =>
        props.type ? textColors[props.type] : ''};
    }
  }
`;
