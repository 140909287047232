import React, { FC, useEffect, useState } from 'react';
import { EmptyText } from '../EmptyText';
import { NonEditableText } from '../NonEditableText';
import TextDatePicker from './components/TextDatePicker';
import { TEditableDatePickerProps } from '../EditableFields.type';
import EditableDatePickerForm from './components/EditableDatePickerForm';
import EditableFieldWrapper from '../EditableFieldWrapper';

export const EditableDatePicker: FC<TEditableDatePickerProps> = (props) => {
  const {
    fontLevel,
    onEdit,
    isLoading,
    selected,
    value: date,
    error,
    icon,
    minMedia,
    fontWeight,
    placeholder,
    required,
    emptyText,
    canEdit,
    canRemove,
    disabledDate,
    color,
  } = props;
  const [value, setValue] = useState(date);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (error) setValue(date);
  }, [error]);

  useEffect(() => {
    setValue(date);
  }, [date]);

  if (canEdit) {
    return (
      <EditableFieldWrapper
        canEdit={canEdit}
        isEditable={isEditable}
        selected={selected}
        icon={icon}
        color={color}
        minMedia={minMedia}
        isLoading={isLoading}
        error={error}
      >
        {isEditable ? (
          <EditableDatePickerForm
            canRemove={canRemove}
            disabledDate={disabledDate}
            onEdit={onEdit}
            placeholder={placeholder}
            setIsEditable={setIsEditable}
            value={value}
            setValue={setValue}
            required={required}
          />
        ) : (
          <>
            {value ? (
              <TextDatePicker
                setIsEditable={setIsEditable}
                value={value}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
              />
            ) : (
              <EmptyText
                emptyText={emptyText}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
                onClick={() => setIsEditable(true)}
              />
            )}
          </>
        )}
      </EditableFieldWrapper>
    );
  }

  return (
    <NonEditableText
      value={value}
      icon={icon}
      fontWeight={fontWeight}
      fontLevel={fontLevel}
    />
  );
};

export default EditableDatePicker;
