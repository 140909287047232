import React, { FC } from 'react';
import { Button } from '../Button';
import { TextArea } from '../TextField';
import { FormItem } from '../Form';
import { validationRules } from '../Form/validationRules';
import { SForm } from './AddComment.style';
import { TAddCommentProps } from './AddComments.type';

export const AddComment: FC<TAddCommentProps> = ({
  form,
  onSubmit,
  isLoading,
  className,
  buttonText = 'Comment',
}) => {
  return (
    <SForm
      form={form}
      autoComplete="off"
      preserve={false}
      onFinish={onSubmit}
      className={className}
    >
      <FormItem name="comment" rules={[validationRules.required()]}>
        <TextArea placeholder="Type something..." rows={4} />
      </FormItem>
      <Button type="primary" htmlType="submit" loading={isLoading}>
        {buttonText}
      </Button>
    </SForm>
  );
};

export default AddComment;
