import React, { FC } from 'react';
import { Colors } from 'core/CssVariables';
import { Icon } from '../../Icon';
import SEditableText from '../EditableText/EditableText.style';
import { Spinner } from '../../Spinner';
import EditableFieldError from '../EditableFieldError';
import { TEditableFieldWrapperProps } from '../EditableFields.type';

export const EditableFieldWrapper: FC<TEditableFieldWrapperProps> = ({
  canEdit,
  icon,
  children,
  isEditable,
  minMedia,
  selected,
  color,
  error,
  isLoading,
  className = '',
}) => {
  return (
    <SEditableText
      canEdit={canEdit}
      onClick={(e) => e.stopPropagation()}
      minMedia={minMedia}
      isEditable={isEditable}
      selected={selected}
      color={color || Colors.DarkGrey}
      className={`mt-1 mr-2 pr-6 editable-text ${className}`}
    >
      {icon && (
        <span>
          <Icon
            color={Colors.DarkGrey}
            icon={icon}
            size={16}
            className="mt-3 mr-1 field-icon"
          />
        </span>
      )}
      {children}
      {isLoading && <Spinner size="default" className="edit-loading mt-2" />}
      <EditableFieldError error={error} />
    </SEditableText>
  );
};

export default EditableFieldWrapper;
