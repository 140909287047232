import styled from 'styled-components';
import { Colors } from 'core/CssVariables';
import { LightTheme } from '../../theme';

const SUploadWrapper = styled.div<{ width?: number; height?: number }>`
  && {
    .upload-wrapper {
      display: inline-flex;
      align-items: center;
      position: relative;
    }

    &&&& {
      .uploader-error {
        display: block;
        min-height: 0;
        color: ${Colors.ErrorColor};

        :hover {
          background-color: ${Colors.BackgroundColor};
        }
      }
    }

    .upload-btn {
      width: ${({ width }) => width || 71}px;
      height: ${({ height }) => height || 71}px;
      border-radius: 50%;
      position: relative;
      background-color: ${Colors.White};

      &.show-border {
        border: 1px dashed ${LightTheme.uploadButton.borderColor};
      }

      .icon:not(.camera-icon) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        path {
          fill: ${LightTheme.uploadButton.iconColor} !important;
        }
      }

      .camera-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 14px;
        border-radius: 50%;
        background-color: ${Colors.PrimaryColor};
      }

      input {
        display: none;
      }

      .upload-image {
        width: ${({ width }) => width || 71}px;
        height: ${({ height }) => height || 71}px;
        position: absolute;
        inset: 0 0 0 0;
        object-fit: cover;
        border-radius: 50%;
        pointer-events: none;

        &:not([src]) {
          display: none;
        }
      }
    }

    .upload-text {
      white-space: nowrap;
      margin-left: 12px;
    }
  }
`;

export default SUploadWrapper;
