import styled, { css } from 'styled-components';
import { Screens } from 'core/CssVariables';

export const CommentWrapper = styled.div`
  display: flex;
  border-radius: 4px;

  .info {
    display: flex;
    align-items: center;

    &.flex-end {
      justify-content: flex-end;
    }

    @media (max-width: ${Screens.ScreensMD}) {
      flex-direction: column;
      align-items: start;
      &.flex-end {
        align-items: flex-end;
      }
      .date {
        margin-left: 0 !important;
      }
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
  }

  .body-wrapper {
    width: 100%;
  }

  .editing-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    textarea {
      resize: none;
      border-radius: 4px;
    }

    .button-wrapper {
      display: flex;
    }
  }
`;

export const SContentWrapper = styled.div<{ $isOwner?: boolean }>`
  display: flex;
  margin: 4px 24px 0 0;
  ${({ $isOwner }) =>
    $isOwner &&
    css`
      justify-content: flex-end;
      margin: 4px 0 0 24px;
    `}
`;
