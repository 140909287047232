import React, { FC, useState } from 'react';
import { Colors } from 'core/CssVariables';
import { TEditableRadioGroup, TRadioValue } from './EditableRadioGroup.types';
import { Title } from '../../Typography';
import { Radio, RadioGroup } from '../../Radio';
import { Col, Row } from '../../Grid';

const EditableRadioGroup: FC<TEditableRadioGroup> = ({
  onClick = () => {
    // do nothing
  },
  value: defaultValue,
  label,
  canEdit,
  options,
  color,
  labelColor,
  fontLevel,
}) => {
  const [value, setValue] = useState<TRadioValue>(defaultValue);
  const onChange = (val: TRadioValue) => {
    setValue(val);
    onClick(val);
  };

  return (
    <Row gutter={[4, 4]} className="py-1">
      <Col span={24}>
        <Title fontLevel={fontLevel || 5} color={labelColor || Colors.DarkGrey}>
          {label}
        </Title>
      </Col>
      <Col span={24}>
        <RadioGroup
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={!canEdit}
          size="small"
        >
          {options?.map((item) => (
            <Radio
              key={item.label}
              value={item.value}
              label={item.label}
              color={color || Colors.DarkGrey}
            />
          ))}
        </RadioGroup>
      </Col>
    </Row>
  );
};
export { EditableRadioGroup };
