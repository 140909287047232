import React, { FC } from 'react';
import { Colors } from 'core/CssVariables';
import Space from 'antd/es/space';
import { TIconNames } from '../Icon/Icon.type';
import { TCollapseProps } from './Collapse.types';
import { SCollapse, SIcon, SPanel } from './Collapse.styles';

const Collapse: FC<TCollapseProps> = ({
  expandIconPosition = 'end',
  collapsible = 'header',
  content,
  bordered = true,
  ...props
}) => {
  return (
    <Space direction="vertical">
      {content.map((item) => (
        <SCollapse
          key={item.header}
          bordered={bordered}
          expandIcon={({ isActive }) => (
            <SIcon
              $isActive={!!isActive}
              className="pointer"
              color={Colors.DarkGrey}
              icon={TIconNames.ARROW_FILLED}
              size={8}
            />
          )}
          expandIconPosition={expandIconPosition}
          collapsible={collapsible}
          {...props}
        >
          <SPanel header={item.header} key="1">
            {item.text}
          </SPanel>
        </SCollapse>
      ))}
    </Space>
  );
};

export { Collapse };
