import React, { FC } from 'react';
import { SAlert } from './Alert.style';
import { TAlertProps } from './Alert.type';

export const Alert: FC<TAlertProps> = ({ message, ...props }) => {
  if (!message) {
    return null;
  }

  return <SAlert message={message} {...props} />;
};
