import React from 'react';
import { Col, Row } from '../../../../Grid';
import { TextIcon } from '../../../../TextIcon';
import { Icon } from '../../../../Icon';
import { TIconNames } from '../../../../Icon/Icon.type';
import { Colors } from 'core/CssVariables';

const ModalTitle = ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <Row justify="space-between">
      <Col span={18}>Select</Col>
      <Col span={4} offset={2}>
        <TextIcon
          onClick={onConfirm}
          label="Done"
          icon={
            <Icon
              color={Colors.Green}
              icon={TIconNames.CHECK_ROUNDED}
              size={16}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default ModalTitle;
