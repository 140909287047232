import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { BorderRadius, Colors, FontSizes, Spacing } from 'core/CssVariables';
import { LightTheme } from '../../theme';
import { TThemeProps } from '../../theme/theme.type';
import { TSelectProps } from './Select.type';
import { Menu, MenuItem } from '../Menu';
import { Modal } from '../Modal';

const sharedStyles = ({
  theme,
  $error,
  $missing,
}: ThemedStyledProps<TSelectProps, TThemeProps>) => css`
  .css-1rhbuit-multiValue {
    border-radius: ${BorderRadius.RadiusMD}px;
    margin: ${Spacing.SpacingXXS}px;
    padding: ${Spacing.SpacingXXS}px ${Spacing.SpacingXS}px;
    font-size: ${FontSizes.FontSM}px;
  }
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    // hover focused select styles
    &,
    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
      background-color: ${theme.input.backgroundColor};
      color: ${theme.input.textColorPrimary};
      border-color: ${($error && theme.input.errorBorderColor) ||
      (!$error && $missing && theme.input.missingBorderColor) ||
      (!$error && !$missing && theme.input.borderColor)};
    }

    .css-14el2xx-placeholder {
      color: ${theme.input.placeholder};
    }

    .icon {
      path {
        fill: ${theme.input.textColorPrimary};
      }
    }
    .css-1rhbuit-multiValue {
      background-color: ${Colors.PrimaryColor};
    }
    + div {
      z-index: 99;
    }
  }

  .css-1insrsq-control {
    // disabled select styles
    cursor: not-allowed;
    background-color: ${theme.input.backgroundColor};
    color: ${theme.input.textColorDisabled};
    border-color: ${theme.input.borderColorDisabled};

    .css-14el2xx-placeholder {
      color: ${theme.input.textColorDisabled};
    }
    .css-1rhbuit-multiValue {
      .css-12jo7m5 {
        color: ${Colors.LightGrey}; //tag text color
      }
      background-color: ${Colors.DisabledColor};
    }
    .icon {
      path {
        fill: ${Colors.LightGrey};
      }
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-12jo7m5 {
    padding: 0;
    font-size: 100%;
  }

  .css-4ljt47-MenuList {
    padding-bottom: 0;
    padding-top: 0;
    border: 0;
  }

  .css-1n7v3ny-option {
    color: ${Colors.Black};
    padding: ${Spacing.SpacingXS}px ${Spacing.SpacingSM}px;
    background-color: ${theme.input.backgroundColor};

    &:hover {
      background-color: ${Colors.BackgroundColor};
    }
  }

  .css-9gakcf-option {
    &,
    &:active {
      color: ${Colors.Black};
      background-color: ${Colors.PrimaryColor};
    }
  }
`;
export const SCreatableSelect = styled(CreatableSelect)<
  ThemedStyledProps<TSelectProps, TThemeProps>
>`
  ${(props) => sharedStyles({ ...props, theme: LightTheme })}
`;

export const SSelect = styled(Select)<
  ThemedStyledProps<TSelectProps, TThemeProps>
>`
  ${(props) => sharedStyles({ ...props, theme: LightTheme })}
`;

export const SMenu = styled(Menu)`
  && {
    height: 240px;
    border-right: none;
    border-radius: 8px;
    overflow: auto;
    margin-bottom: 8px;
    &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: ${Colors.PrimaryColor};
      color: ${Colors.Black};
    }
    font-size: ${FontSizes.FontMD}px;
    .ant-menu-item:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .ant-menu-item:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .ant-menu-item,
    .ant-menu-item:not(:last-child) {
      margin: 0;
      :hover {
        background-color: ${Colors.PrimaryColor};
        color: ${Colors.Black};
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: ${Colors.PrimaryColor};
      color: ${Colors.Black};
    }
  }
`;

export const SMenuItem = styled(MenuItem)`
  font-size: ${FontSizes.FontMD}px;
  &.ant-menu-item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &.ant-menu-item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &.ant-menu-item,
  &.ant-menu-item:not(:last-child) {
    margin: 0;
    :hover {
      background-color: ${Colors.PrimaryColor};
      color: ${Colors.Black};
    }
  }
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${Colors.PrimaryColor};
    color: ${Colors.Black};
  }
`;

export const SSearchWrapper = styled.div`
  padding: 12px 16px;
`;

export const SModal = styled(Modal)`&&{
  .ant-modal-content {
    .ant-modal-body {
      width: 100%;
      height: 100%;
      .empty-content { 
        align-items: center;
        display: flex;
        justify-content: center;
        height: 240px;
        margin-bottom: 8px;
      }
    }
    .ant-modal-header {
      .ant-modal-title {
        font-size: ${FontSizes.FontSM}px;
        line-height: 24px;
        margin-right: 0px;
      }
    }
}`;
