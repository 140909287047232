import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { TextIcon } from '../../../TextIcon';
import { Icon } from '../../../Icon';
import SCardTabMobile from './CardTabsMobile.style';
import CardAvatar from '../CardAvatar';
import { TCardTabProps } from '../../CardTabs.type';
import { Col, Row } from '../../../Grid';

export const CardTabsMobile: FC<TCardTabProps> = ({
  avatar,
  title,
  active,
}) => {
  const { icon: avatarIcon, src } = avatar || {};
  const { text, icon } = title;
  return (
    <div>
      <SCardTabMobile color="grey" $active={active}>
        <Row gutter={[8, 8]} align="middle" wrap={false}>
          {avatar && (
            <Col>
              <CardAvatar iconName={avatarIcon} src={src} size={16} />
            </Col>
          )}
          <Col>
            <TextIcon
              labelColor={Colors.Black}
              fontLevel={5}
              fontWeight={FontWeights.SemiBold}
              revert
              label={text}
              {...(icon && {
                icon: <Icon icon={icon} size={16} className="ml-1" />,
              })}
            />
          </Col>
        </Row>
      </SCardTabMobile>
    </div>
  );
};

export default CardTabsMobile;
