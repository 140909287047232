import * as React from 'react';
import { FC } from 'react';
import { PageSpinner } from '../../../Spinner';
import { Row } from '../../../Grid';
import { NoResult } from '../NoResultWrapper';
import { TPageBodyProps } from '../../PageWrapper.type';
import { SPageBody, SPageSpinner } from './SPageBody.style';

export const PageBody: FC<TPageBodyProps> = ({
  loading,
  noResult,
  children,
  headerHeight,
  footerHeight,
}) => {
  return (
    <Row className="content">
      <SPageBody
        $headerHeight={headerHeight}
        $footerHeight={footerHeight}
        span={24}
        className="content"
      >
        {loading ? (
          <SPageSpinner>
            <PageSpinner />
          </SPageSpinner>
        ) : (
          <>
            {!noResult && children}
            <NoResult noResult={noResult} />
          </>
        )}
      </SPageBody>
    </Row>
  );
};
