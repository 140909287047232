import React, { FC, ChangeEventHandler } from 'react';
import { Colors } from 'core/CssVariables';
import { Icon } from '../../../Icon';
import { TextField } from '../../../TextField';
import { Form, FormItem } from '../../../Form';
import { TIconNames } from '../../../Icon/Icon.type';
import { validationRules } from '../../../Form/validationRules';
import useForm from '../../../../hooks/useForm';
import { TEditableEmailFormProps } from '../EditableEmail.type';

const EditableEmailForm: FC<TEditableEmailFormProps> = ({
  placeholder,
  setIsEditable,
  email,
  setValue,
  onEdit,
  required,
}) => {
  const { setCustomFieldValue, form } = useForm<{ email: string }>();

  const handleCustomField: ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value;
    setCustomFieldValue('email', val);
    setValue(val);
  };

  const handlePressEnter = async () => {
    try {
      const formValues = await form.validateFields();
      if (form.isFieldTouched('email')) {
        onEdit(formValues.email);
      }
      setIsEditable(false);
    } catch (e) {
      // do nothing
    }
  };

  const formValidationRules = [
    validationRules.email(),
    ...(required ? [validationRules.required()] : []),
  ];

  return (
    <Form
      form={form}
      name="editablePhone"
      autoComplete="off"
      preserve={false}
      initialValues={{ email }}
    >
      <FormItem name="email" className="my-0" rules={formValidationRules}>
        <TextField
          autoFocus
          placeholder={placeholder}
          onChange={handleCustomField}
          onPressEnter={handlePressEnter}
          onBlur={handlePressEnter}
          className="no-border"
          suffix={
            <Icon icon={TIconNames.ENTER} color={Colors.DarkGrey} size={15} />
          }
        />
      </FormItem>
    </Form>
  );
};

export default EditableEmailForm;
