import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Tag, ETagTypes } from '../../../Tag';
import { Col, Row } from '../../../Grid';
import { Icon } from '../../../Icon';
import { Text } from '../../../Typography';
import { TextIcon } from '../../../TextIcon';
import CardAvatar from '../CardAvatar';
import SCardTabsDesktop from './CardTabsDesktop.style';
import { TCardTabsDesktopProps } from './CardTabsDescktop.type';

export const CardTabsDesktop: FC<TCardTabsDesktopProps> = ({
  cardTitle,
  avatar,
  subTitle,
  title,
  active,
}) => {
  const { icon: avatarIcon, src } = avatar || {};
  const { text, icon } = title;
  return (
    <div>
      <SCardTabsDesktop color="grey" active={active}>
        <Tag
          type={ETagTypes.PRIMARY}
          color={active ? Colors.Black : undefined}
          className="tag"
        >
          {cardTitle}
        </Tag>
        <Row gutter={[8, 8]} align="middle" wrap={false}>
          {avatar && (
            <Col>
              <CardAvatar iconName={avatarIcon} src={src} active={active} />
            </Col>
          )}
          <Col>
            <Row gutter={[4, 4]} align="stretch" justify="start">
              <Col span={24}>
                <TextIcon
                  labelColor={Colors.Black}
                  fontLevel={4}
                  fontWeight={FontWeights.Medium}
                  label={text}
                  className="title"
                  {...(icon && {
                    icon: <Icon icon={icon} size={16} className="ml-1" />,
                  })}
                />
              </Col>
              {subTitle && (
                <Col span={24}>
                  <Text
                    fontLevel={6}
                    fontWeight={FontWeights.Medium}
                    color={Colors.DarkGrey}
                  >
                    {subTitle}
                  </Text>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </SCardTabsDesktop>
    </div>
  );
};

export default CardTabsDesktop;
