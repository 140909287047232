import React, { FC } from 'react';
import { Colors } from 'core/CssVariables';
import { TIconNames } from '../../../Icon/Icon.type';
import { TBackButton } from '../../PageWrapper.type';
import SBackButton from './BackButton.style';

const BackButton: FC<TBackButton> = ({
  onClick = () => {
    /* do nothing */
  },
  title,
}) => {
  const onBackClick = () => {
    onClick();
  };
  if (!title) return null;
  return (
    <SBackButton
      onClick={onBackClick}
      iconColor={Colors.DarkGrey}
      size={15}
      icon={TIconNames.ARROW_LEFT_WITH_TALE}
      labelColor={Colors.DarkGrey}
      label={title}
    />
  );
};

export default BackButton;
