import React, { FC } from 'react';

import { TToolTipProps } from './ToolTip.type';

import SToolTip from './ToolTip.style';

const ToolTip: FC<TToolTipProps> = (props) => {
  return <SToolTip {...props} />;
};

export { ToolTip };
