import React, { FC, useState } from 'react';
import { Avatar } from '../Avatar';
import CommentBody from './components/CommentBody';
import CommentHeader from './components/CommentHeader';
import CommentFooter from './components/CommentFooter';
import CommentEditingText from './components/CommentEditingText';
import { CommentWrapper } from './Comment.style';
import { TCommentProps } from './Comment.type';

export const Comment: FC<TCommentProps> = ({
  photoUrl,
  name,
  date,
  isEdited,
  commentContent,
  onDelete,
  commentId,
  firstLetters,
  canEdit,
  isUpdating,
  onSubmitEdit = () => {
    // do nothing
  },
  modifiedDate,
  isOwner,
  className = '',
}) => {
  const [editItem, setEditItem] = useState('');

  const handleEditTextClick = (id: string) => {
    setEditItem(id);
  };

  const handleSubmitEditItem = async (value: string) => {
    await onSubmitEdit(editItem, value);
    setEditItem('');
  };

  return (
    <CommentWrapper className={`p-3 ${className}`}>
      {(photoUrl || firstLetters) && (
        <div>
          <Avatar src={photoUrl} className="mr-3">
            {!photoUrl && firstLetters}
          </Avatar>
        </div>
      )}
      <div className="body-wrapper">
        <CommentHeader
          name={name}
          date={date}
          isOwner={isOwner}
          isEdited={isEdited}
          modifiedDate={modifiedDate}
        />
        {editItem && editItem === commentId ? (
          <CommentEditingText
            onSubmitEditText={handleSubmitEditItem}
            defaultValue={commentContent}
            isUpdating={!!isUpdating}
            setEditItem={setEditItem}
          />
        ) : (
          <>
            <CommentBody commentContent={commentContent} isOwner={isOwner} />
            <CommentFooter
              onEdit={handleEditTextClick}
              onDelete={onDelete}
              commentId={commentId}
              canEdit={canEdit}
            />
          </>
        )}
      </div>
    </CommentWrapper>
  );
};

export default Comment;
