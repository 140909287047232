import React, { FC } from 'react';
import { Colors } from 'core/CssVariables';
import { ToolTip } from '../../ToolTip';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';
import { TEditableFieldErrorProps } from '../EditableFields.type';

export const EditableFieldError: FC<TEditableFieldErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <ToolTip title={error} placement="topRight" className="edit-error">
      <span>
        <Icon color={Colors.ErrorColor} icon={TIconNames.INFO} size={16} />
      </span>
    </ToolTip>
  );
};

export default EditableFieldError;
