import styled from 'styled-components';
import { Row } from '../../Grid';

export const SRow = styled(Row)`
  && {
    .edit-icon {
      visibility: hidden;
    }
    &:hover {
      .edit-icon {
        visibility: visible;
      }
    }
  }
`;
