import React, { FC } from 'react';
import { Icon } from '../../../Icon';
import { Avatar } from '../../../Avatar';
import { TAvatarProps } from '../../../Avatar/Avatar.type';
import { TIconNames } from '../../../Icon/Icon.type';

export const CardAvatar: FC<
  TAvatarProps & { iconName?: TIconNames; size?: number }
> = ({ iconName, size, ...other }) => {
  const iconSize = (size || 40) - (size || 40) / 4;
  return (
    <Avatar
      size={size || 40}
      {...(iconName && { icon: <Icon icon={iconName} size={iconSize} /> })}
      {...other}
    />
  );
};
export default CardAvatar;
