import React, { FC } from 'react';
import { ETagTypes, TTagProps, EPositionedTagTypes } from './Tag.type';
import STag from './Tag.style';

const Tag: FC<TTagProps> = ({
  type,
  positionedType,
  fontLevel,
  fontWeight,
  textColor,
  borderColor,
  padding,
  ...props
}) => {
  return (
    <STag
      $type={type}
      $positionedType={positionedType}
      $fontLevel={fontLevel}
      $fontWeight={fontWeight}
      $textColor={textColor}
      $borderColor={borderColor}
      $padding={padding}
      {...props}
    />
  );
};

export { Tag, ETagTypes, EPositionedTagTypes };
