import React, { FC } from 'react';
import { Form, FormItem } from '../../../Form';
import useForm from '../../../../hooks/useForm';
import ImageUploader from '../../../ImageUploader';
import { TFile } from '../../../../types/generalTypes';
import { TEditablePhotoFormProps } from '../EditablePhoto.type';

const EditablePhotoForm: FC<TEditablePhotoFormProps> = ({
  photoUrl,
  onEdit,
  width,
  height,
  isLoading,
  uploadFile,
  uploadedImage,
}) => {
  const { setCustomFieldValue, form } = useForm();

  const handleCustomField = (data: TFile) => {
    setCustomFieldValue('photoUrl', data.url);
    onEdit(data.url);
  };

  return (
    <Form
      form={form}
      name="editablePhoto"
      autoComplete="off"
      preserve={false}
      initialValues={{ photoUrl }}
    >
      <FormItem name="photoUrl" className="my-0">
        <ImageUploader
          uploadFile={uploadFile}
          uploadedImage={uploadedImage}
          onChange={handleCustomField}
          height={height}
          width={width}
          url={photoUrl}
          isLoading={isLoading}
        />
      </FormItem>
    </Form>
  );
};

export default EditablePhotoForm;
