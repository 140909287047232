import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Text } from '../../Typography';
import { TCommentBodyProps } from '../Comment.type';
import { SContentWrapper } from '../Comment.style';

const CommentBody: FC<TCommentBodyProps> = ({ commentContent, isOwner }) => {
  return (
    <SContentWrapper $isOwner={isOwner}>
      <Text fontLevel={4} fontWeight={FontWeights.Regular} color={Colors.Black}>
        {commentContent}
      </Text>
    </SContentWrapper>
  );
};

export default CommentBody;
