import React, { FC } from 'react';
import { Row } from '../Grid';
import { TabPane } from '../Tabs/Tabs.style';
import { SCardTabs } from './CardTabs.style';
import { TCardTabsProps } from './CardTabs.type';
import CardTabsDesktop from './components/CardTabsDesktop';
import CardTabsFooter from './components/CardTabsFooter';
import { CardTabsMobile } from './components/CardTabsMobile';
import CardTabsPageWrapper from './components/CardTabsPageWrapper';

const CardTabs: FC<TCardTabsProps> = (props) => {
  const {
    tabsData,
    page,
    footer,
    activeTab,
    setActiveTab,
    disabled,
    className,
    isMobile,
  } = props;
  return (
    <CardTabsPageWrapper {...page} withPage={!isMobile}>
      <SCardTabs
        className={className}
        $withPage={!!page?.title}
        activeKey={activeTab}
        onChange={setActiveTab}
        type="card"
        tabBarGutter={isMobile ? 0 : 24}
        tabPosition={isMobile ? 'top' : 'left'}
      >
        {tabsData.map((item) => (
          <TabPane
            disabled={disabled}
            tab={
              isMobile ? (
                <CardTabsMobile active={activeTab === item.key} {...item.tab} />
              ) : (
                <CardTabsDesktop
                  active={activeTab === item.key}
                  {...item.tab}
                />
              )
            }
            key={item.key}
          >
            <Row className="content-wrapper">{item.content}</Row>
            <CardTabsFooter renderFooter={!!isMobile}>{footer}</CardTabsFooter>
          </TabPane>
        ))}
      </SCardTabs>
      <CardTabsFooter renderFooter={!isMobile}>{footer} </CardTabsFooter>
    </CardTabsPageWrapper>
  );
};

export { CardTabs };
