import React, { FC } from 'react';
import { TTextIconProps } from './TextIcon.type';
import { STextIcon } from './TextIcon.style';
import { Icon } from '../Icon';
import { Text } from '../Typography';

const TextIcon: FC<TTextIconProps> = ({
  icon,
  iconColor,
  label,
  labelColor,
  fontLevel,
  onClick,
  className,
  size,
  fontWeight,
  revert = false,
  ...props
}) => {
  return (
    <STextIcon
      $revert={revert}
      onClick={onClick}
      align="middle"
      className={`${className} ${onClick ? 'pointer' : ''}`}
      {...props}
    >
      {icon &&
        (typeof icon === 'string' ? (
          <Icon icon={icon} color={iconColor} size={size} />
        ) : (
          icon
        ))}
      <Text fontLevel={fontLevel} color={labelColor} fontWeight={fontWeight}>
        {label}
      </Text>
    </STextIcon>
  );
};

export { TextIcon };
