import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { ToolTip } from '../../ToolTip';
import { Text } from '../../Typography';
import { TCommentHeaderProps } from '../Comment.type';

const CommentHeader: FC<TCommentHeaderProps> = ({
  name,
  date,
  isEdited,
  modifiedDate,
  isOwner,
}) => {
  return (
    <div className={`info ${isOwner ? 'flex-end' : ''}`}>
      <Text
        fontLevel={5}
        fontWeight={FontWeights.Regular}
        className="editable-title-color"
      >
        {name}
      </Text>
      <div>
        <Text
          fontLevel={6}
          fontWeight={FontWeights.Regular}
          color={Colors.LightGrey}
          className="date ml-3"
        >
          {date}
        </Text>
        {isEdited && (
          <ToolTip title={modifiedDate}>
            <Text
              fontLevel={6}
              fontWeight={FontWeights.Regular}
              color={Colors.LightGrey}
              className="ml-3"
            >
              Edited
            </Text>
          </ToolTip>
        )}
      </div>
    </div>
  );
};

export default CommentHeader;
