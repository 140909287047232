import React, { FC } from 'react';
import { TPageWrapperProps } from '../../PageWrapper/PageWrapper.type';
import { PageWrapper } from '../../PageWrapper';

export const CardTabsPageWrapper: FC<
  TPageWrapperProps & { withPage: boolean,  children?: React.ReactNode }
> = (props) => {
  const { title, children, withPage } = props;
  return title && withPage ? (
    <PageWrapper {...props}> {children} </PageWrapper>
  ) : (
    <>{children}</>
  );
};

export default CardTabsPageWrapper;
