import { BorderRadius, Colors, Spacing } from 'core/CssVariables';
import styled, { css } from 'styled-components';
import { TCardSectionColor, TSCardSectionProps } from './CardSection.type';
import { Row } from '../Grid';

export const colorsMapper: { [key in TCardSectionColor]: Colors } = {
  white: Colors.White,
  grey: Colors.BackgroundColor,
  danger: Colors.ErrorOpacity,
};

export const SCardSection = styled(Row)<TSCardSectionProps>`
  && {
    position: relative;
    flex-direction: column;
    padding: ${Spacing.SpacingLG}px;
    background-color: ${({ color }) => colorsMapper[color]};
    ${({ $active }) =>
      $active &&
      css`
        background-color: ${Colors.PrimaryColor};
      `};
    border-radius: ${BorderRadius.RadiusXS}px;
    height: ${({ height }) => height || 'auto'};
    min-height: ${({ $minHeight }) => $minHeight || ''};
  }
`;
