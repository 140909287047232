import React, { useState, forwardRef, useImperativeHandle } from 'react';
import uniqBy from 'lodash/uniqBy';
import Menu from './Menu';
import { TSelectModalProps, TSelectOptionTypeUi } from '../../../Select.type';
import { SModal } from '../../../Select.style';
import { ModalWidthEnum } from '../../../../Modal/Modal.type';
import ModalTitle from './ModalTitle';

const SlelectModal = forwardRef(
  (
    {
      onClose = () => {},
      value,
      isMulti,
      onSelect,
      ...props
    }: TSelectModalProps,
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [selectedValues, setSelectedValues] = useState<TSelectOptionTypeUi[]>(
      (value as TSelectOptionTypeUi[]) || []
    );

    useImperativeHandle(ref, () => ({
      open: () => setVisible(true),
    }));

    const handleConfirm = (
      value: TSelectOptionTypeUi | TSelectOptionTypeUi[]
    ) => {
      onSelect(value);
      setVisible(false);
      onClose();
    };

    const handleSelect = (
      option: TSelectOptionTypeUi,
      isDeselect?: boolean
    ) => {
      const { value: selectedValue, label, __isNew__ } = option;

      if (!isMulti) {
        handleConfirm({
          value: selectedValue,
          label,
          __isNew__: __isNew__ || false,
        });
        return;
      }
      setSelectedValues((oldSelected) => {
        const newValues = uniqBy(
          [
            { value: selectedValue, label, __isNew__: __isNew__ || false },
            ...(oldSelected as TSelectOptionTypeUi[]),
          ],
          'value'
        ).filter((item) => !isDeselect || item.value !== selectedValue);
        if (__isNew__) {
          handleConfirm(newValues);
        }
        return newValues;
      });
    };

    const handleClose = () => {
      onClose();
      setVisible(false);
    };

    return (
      <SModal
        title={
          isMulti && (
            <ModalTitle onConfirm={() => handleConfirm(selectedValues)} />
          )
        }
        centered
        bodyStyle={{ padding: 0 }}
        closable={false}
        footer={null}
        visible={visible}
        onCancel={handleClose}
        size={ModalWidthEnum.Small}
        destroyOnClose
        className="mobile-select-modal"
      >
        <Menu
          {...props}
          onSelect={handleSelect}
          isMulti={isMulti}
          selectedOption={value}
        />
      </SModal>
    );
  }
);

export default SlelectModal;
