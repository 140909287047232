import React from 'react';
import { FontWeights } from 'core/CssVariables';
import { Row } from '../../../Grid';
import { Icon } from '../../../Icon';
import { Title } from '../../../Typography';
import { TUploadedFileProps } from '../../Uploader.type';
import { TIconNames } from '../../../Icon/Icon.type';

const DocumentItem = ({ file, onDelete, readOnly }: TUploadedFileProps) => {
  return (
    <Row align="middle">
      <Icon icon={TIconNames.FILE_ICON} size={24} className="mr-4" />
      <Title fontLevel={5} fontWeight={FontWeights.Medium}>
        {file.name}
      </Title>
      {!readOnly && (
        <Icon
          icon={TIconNames.CLOSE_CIRCLE}
          size={16}
          className="ml-2 pointer"
          onClick={onDelete}
        />
      )}
    </Row>
  );
};

export { DocumentItem };
