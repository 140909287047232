import React from 'react';
import { SMenu, SMenuItem } from './Menu.style';
import { TMenuItemProps, TMenuProps } from './Menu.types';

const Menu: React.FC<TMenuProps> = (props) => {
  return <SMenu {...props} />;
};
const MenuItem: React.FC<TMenuItemProps> = (props) => {
  return <SMenuItem {...props} />;
};

export { Menu, MenuItem };
