import styled from 'styled-components';
import { ScrollableContainer } from '../../../ScrollableContainer';

const SNoResultsWrapper = styled(ScrollableContainer)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;

    .no-result {
      color: ${({theme}) => theme.noResult.color};
    }
  }
`;
export default SNoResultsWrapper;
