import styled, { css } from 'styled-components';
import Divider from 'antd/es/divider';
import { TDividerProps } from './Divider.type';

export const SDivider = styled(Divider)<TDividerProps>`
  && {
    ${({ type }) =>
      type === 'vertical' &&
      css`
        height: 100%;
      `}
    ${({ type }) =>
      type === 'horizontal' &&
      css`
        margin: 0;
      `}
  }
`;
