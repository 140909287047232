import styled, { css } from 'styled-components';
import { BorderRadius, Colors, Screens } from 'core/CssVariables';
import { LightTheme } from '../../../theme';

const SEditableText = styled.div<{
  selected?: boolean;
  isEditable?: boolean;
  canEdit?: boolean;
  minMedia?: Screens;
}>`
  && {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    min-height: ${({ canEdit }) => (canEdit ? '40px' : '')};

    #editableAddress,
    .css-b62m3t-container {
      width: 100%;
    }

    .ant-typography-edit-content {
      left: -8px;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      font-weight: 500;
    }

    .ant-typography {
      transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      border-radius: ${BorderRadius.RadiusXXS}px;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: ${({ canEdit }) => (canEdit ? '40px' : '')};
    }

    .css-1s2u09g-control,
    .css-1pahdxg-control {
      min-height: ${({ canEdit }) => (canEdit ? '40px' : '')};

      .css-319lph-ValueContainer {
        padding: 2px 5px;
      }
    }

    .anticon-loading {
      path {
        fill: ${() => LightTheme.editableText.spinner};
      }
    }

    .ant-input,
    .ant-input:focus {
      color: ${({ selected }) =>
        selected ? LightTheme.editableText.color : LightTheme.color};
      box-shadow: none;
      padding: 8px 20px 8px 7px;
      border: 1px solid ${Colors.PrimaryColor};
      background-color: ${() => LightTheme.backgroundColor};

      &.text-area {
        padding: 4px 8px;
        margin: -1px;
      }
    }

    .ant-form-item-control-input .ant-input-affix-wrapper {
      padding: 8px 4px;

      .ant-input {
        border: none;
        padding: 0 1px 0 3px;
      }
    }

    .ant-form {
      width: 100%;
    }

    .ant-typography-edit-content-confirm {
      position: absolute;
      top: 14px;
    }

    .ant-typography-edit {
      visibility: hidden;
    }

    .edit-error,
    .edit-loading {
      position: absolute;
      right: 0;
      margin: 8px 0 0 12px;
      visibility: visible;
    }

    ${({ isEditable, color, canEdit = true }) =>
      !isEditable &&
      css`
        width: fit-content;

        .ant-typography {
          color: ${color || LightTheme.editableText.color};
          padding-right: 12px;
          ${canEdit &&
          css`
            &:hover {
              background-color: ${LightTheme.input.backgroundColor};

              .ant-typography-edit {
                visibility: visible;
              }

              .edit-error,
              .edit-loading {
                visibility: hidden;
              }
            }
          `}
        }
      `}
    ${({ minMedia, selected, isEditable }) => css`
      @media (min-width: ${minMedia}) {
        .ant-input:focus {
          color: ${selected && Colors.DarkGrey};
          background-color: ${selected && Colors.White};
        }

        .ant-typography {
          color: ${selected && Colors.White};

          &:hover {
            color: ${selected && Colors.DarkGrey};
            background-color: ${!isEditable && selected && Colors.White};
          }
        }

        .edit-loading {
          path {
            fill: ${selected && Colors.White};
          }
        }
    `}
  }
`;

export default SEditableText;
