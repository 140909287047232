import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Icon } from '../../../Icon';
import { TIconNames } from '../../../Icon/Icon.type';
import { TTextViewProps } from '../EditableTextArea.type';
import { SText } from '../EditableTextArea.style';

const TextView: FC<TTextViewProps> = ({
  text,
  setIsEditable,
  fontLevel,
  fontWeight,
}) => {
  return (
    <SText
      fontLevel={fontLevel || 5}
      fontWeight={fontWeight || FontWeights.Medium}
      onClick={() => setIsEditable(true)}
      editable={{
        icon: (
          <Icon
            icon={TIconNames.EDIT}
            color={Colors.Blue}
            size={16}
            className="edit-icon ml-2"
          />
        ),
      }}
    >
      {text}
    </SText>
  );
};

export default TextView;
