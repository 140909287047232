import React, { FC } from 'react';
import { TScrollableContainerProps } from './ScrollableContainer.type';
import { SScrollableContainer } from './ScrollableContainer.style';

const ScrollableContainer: FC<TScrollableContainerProps> = ({
  className = '',
  maxHeight,
  ...props
}) => {
  return (
    <SScrollableContainer
      className={`customScroll ${className}`}
      $maxHeight={maxHeight}
      {...props}
    />
  );
};
export { ScrollableContainer };
