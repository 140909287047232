import { RuleObject } from 'antd/lib/form';
import { TValidationRules } from './Form.type';

export const validationRules: TValidationRules = {
  required: (fieldName) => ({
    validator(_: RuleObject, value) {
      if (
        (typeof value === 'string' && value.trim()) ||
        (typeof value !== 'string' && !!value)
      ) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          fieldName
            ? `The ${fieldName} is required!`
            : 'This field is required!'
        )
      );
    },
  }),
  email: () => ({
    type: 'email',
    message: 'Incorrect Email Address!',
  }),
};
