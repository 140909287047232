import styled from 'styled-components';
import { Colors, BorderRadius } from 'core/CssVariables';
import { Row } from '../Grid';

export const SFileWrapper = styled(Row)`
  && {
    border: 1px solid ${Colors.DisabledColor};
    border-radius: ${BorderRadius.RadiusXS}px;
    background-color: ${Colors.White};
    padding: 8px 12px;
    width: 100%;

    .file-name {
      .ant-typography {
        text-decoration: underline;
        word-break: break-all;
      }
    }
  }
`;
