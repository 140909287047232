import React, { FC } from 'react';
import moment from 'moment';
import { Colors, FontFamilies, FontWeights } from 'core/CssVariables';
import { Icon } from '../Icon';
import { Col, Row } from '../Grid';
import { TextIcon } from '../TextIcon';
import { SecondaryText } from '../Typography';
import { TIconNames } from '../Icon/Icon.type';
import { SFileWrapper } from './File.style';
import { TFileProps } from './File.type';

export const File: FC<TFileProps> = ({
  filesCount,
  onDelete = () => {
    /* do nothing */
  },
  onView = () => {
    /* do nothing */
  },
  file,
  hideDelete = false,
  onDownload,
  canEdit,
  className = '',
}) => {
  return (
    <SFileWrapper
      align="middle"
      className={`mt-3 mb-3 ${className}`}
      justify="space-between"
    >
      <Row
        wrap={false}
        align="middle"
        justify="space-between"
        className="w-100"
      >
        <Col>
          {!filesCount && file && (
            <SecondaryText
              fontLevel={6}
              fontFamily={FontFamilies.FontSecondary}
              className="mb-1"
            >
              Uploaded by {file?.createdBy} :{' '}
              {moment(file?.createdDate).format('MM/DD/YYYY')}
            </SecondaryText>
          )}

          <TextIcon
            fontLevel={5}
            fontWeight={FontWeights.Medium}
            labelColor={Colors.DarkBlue}
            icon={TIconNames.FILE_ICON}
            className="file-name"
            onClick={() => {
              if (canEdit || filesCount) {
                onView();
              }
            }}
            label={
              !filesCount
                ? file?.name || 'Upload documents'
                : `Included ${filesCount} doc(s)`
            }
          />
        </Col>
        <Col>
          <Row wrap={false} align="middle">
            {filesCount || file ? (
              <>
                <Icon
                  icon={TIconNames.DOWNLOAD}
                  size={20}
                  color={Colors.DarkGrey}
                  className={`mr-3 ml-3 ${canEdit ? 'pointer' : ''}`}
                  onClick={() => onDownload()}
                />
                {!hideDelete && canEdit && (
                  <Icon
                    icon={TIconNames.DELETE}
                    color={Colors.DarkGrey}
                    size={20}
                    className="pointer"
                    onClick={() => onDelete()}
                  />
                )}
              </>
            ) : (
              <>
                {canEdit && (
                  <Icon
                    icon={TIconNames.UPLOAD}
                    size={14}
                    color={Colors.DarkGrey}
                    className="mr-3 ml-3 pointer"
                    onClick={() => onView()}
                  />
                )}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </SFileWrapper>
  );
};

export default File;
