import styled from 'styled-components';
import Radio from 'antd/es/radio';
import { Colors, FontSizes, FontWeights } from 'core/CssVariables';
import { RadioCompoundedComponent } from './Radio.type';

const SRadio = styled<RadioCompoundedComponent>(Radio)`
  && {
    &.ant-radio-wrapper {
      margin-right: 16px;
      align-items: flex-start;
    }

    .ant-radio:not(.ant-radio-disabled) {
      .ant-radio-inner {
        background-color: ${Colors.White};
        border-color: ${({ color }) => color || Colors.DarkGrey};
      }
    }

    && .ant-typography {
      color: ${({ color }) => color || Colors.Black};
    }

    .ant-radio-checked:not(.ant-radio-disabled) {
      .ant-radio-inner {
        border-color: ${Colors.PrimaryColor};
        ::after {
          background-color: ${Colors.PrimaryColor};
        }
      }
      ::after {
        border: none;
      }
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
    .radio-label {
      line-height: 21px;
      font-size: ${FontSizes.FontSM}px;
      font-weight: ${FontWeights.Medium};
      color: ${Colors.Black};
    }

    &.ant-radio-wrapper-disabled {
      &,
      * {
        cursor: default;
      }
      .radio-label {
        color: ${Colors.LightGrey};
      }
    }
  }
`;

export default SRadio;
