import React from 'react';
import { Icon } from '../../../Icon';
import { TUploadedFileProps } from '../../Uploader.type';
import { TIconNames } from '../../../Icon/Icon.type';
import { SImageItemWrapper } from './ImageItem.style';
import { Image } from '../../../Image';

const ImageItem = ({ file, onDelete, readOnly }: TUploadedFileProps) => {
  return (
    <SImageItemWrapper>
      <Image src={file.url} alt={file.name} />
      {!readOnly && (
        <Icon
          icon={TIconNames.CLOSE_CIRCLE}
          size={16}
          className="close-icon pointer"
          onClick={onDelete}
        />
      )}
    </SImageItemWrapper>
  );
};

export { ImageItem };
