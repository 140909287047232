import React from 'react';
import SCheckbox from './Checkbox.style';
import { TCheckboxProps } from './Checbox.type';

const Checkbox = (props: TCheckboxProps) => {
  const { label } = props;
  return <SCheckbox {...props}>{label} </SCheckbox>;
};

export { Checkbox };
