import React, { FC, useEffect, useState } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Text } from '../../Typography';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';
import { EmptyText } from '../EmptyText';
import { NonEditableText } from '../NonEditableText';
import { TEditableTextProps } from '../EditableFields.type';
import EditableFieldWrapper from '../EditableFieldWrapper';

export const EditableText: FC<TEditableTextProps> = (props) => {
  const {
    fontLevel,
    onEdit,
    isLoading,
    selected,
    title,
    error,
    icon,
    minMedia,
    emptyText,
    fontWeight,
    color,
    canRemove,
    canEdit,
    type,
    hasTooltip = false,
    ...otherProps
  } = props;
  const [value, setValue] = useState(title);
  const [isEditable, setIsEditable] = useState(false);
  const onHandleClick = () => {
    setIsEditable(true);
  };
  const onHandleChange = (returnedValue: string) => {
    let newValue = returnedValue;
    if (type === 'number' && returnedValue) {
      newValue = returnedValue.replace(/[^\d.]/g, '');
    }
    setIsEditable(false);
    if (!canRemove && !newValue) {
      setValue(title);
      return;
    }
    if (title !== newValue || error) {
      setValue(newValue);
      if (onEdit) onEdit(newValue);
    }
  };
  useEffect(() => {
    if (error) setValue(title);
  }, [error]);

  useEffect(() => {
    setValue(title);
  }, [title]);

  if (canEdit) {
    return (
      <EditableFieldWrapper
        canEdit={canEdit}
        isEditable={isEditable}
        selected={selected}
        icon={icon}
        color={color}
        minMedia={minMedia}
        isLoading={isLoading}
        error={hasTooltip ? error : ''}
      >
        {value || isEditable ? (
          <Text
            className="pl-2"
            onClick={onHandleClick}
            editable={{
              editing: isEditable,
              onChange: onHandleChange,
              triggerType: ['text', 'icon'],
              enterIcon: (
                <Icon
                  icon={TIconNames.ENTER}
                  color={Colors.LightGrey}
                  size={15}
                  className="ml-2"
                />
              ),
              icon: (
                <Icon
                  icon={TIconNames.EDIT}
                  color={Colors.Blue}
                  size={16}
                  className="edit-icon ml-2"
                />
              ),
            }}
            fontLevel={fontLevel || 5}
            fontWeight={fontWeight || FontWeights.Medium}
            {...otherProps}
          >
            {value}
          </Text>
        ) : (
          <EmptyText
            emptyText={emptyText}
            fontLevel={fontLevel}
            onClick={onHandleClick}
          />
        )}
      </EditableFieldWrapper>
    );
  }
  return (
    <NonEditableText
      value={value}
      icon={icon}
      fontWeight={fontWeight}
      fontLevel={fontLevel}
      color={color}
    />
  );
};

export default EditableText;
