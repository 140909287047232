import { Menu, MenuItem } from '../../../Menu';
import { TMenuProps, TMenuItemProps } from '../../../Menu/Menu.types';
import styled from 'styled-components';
import { Colors, FontSizes } from 'core/CssVariables';

const SMenu = styled(Menu)<TMenuProps>`
  border-right: none;
  border-radius: 8px;
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${Colors.PrimaryColor};
    color: ${Colors.Black};
  }
`;

const SMenuItem = styled(MenuItem)<TMenuItemProps>`
  font-size: ${FontSizes.FontMD}px;
  &.ant-menu-item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &.ant-menu-item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &.ant-menu-item,
  &.ant-menu-item:not(:last-child) {
    margin: 0;
    :hover {
      background-color: ${Colors.PrimaryColor};
      color: ${Colors.Black};
    }
  }
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${Colors.PrimaryColor};
    color: ${Colors.Black};
  }
`;

export { SMenu, SMenuItem };
