import React from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from '../../../DatePicker';
import { TEditableDatePickerFormProps } from '../../EditableFields.type';

const EditableDatePickerForm: React.FC<TEditableDatePickerFormProps> = ({
  setIsEditable,
  value,
  onEdit,
  setValue,
  placeholder,
  disabledDate,
}) => {
  const handleChange = (date: Moment | null) => {
    if (date) {
      onEdit(moment(date).format('MM/DD/yyyy'));
      setValue(moment(date).format('MM/DD/yyyy'));
    }
    setIsEditable(false);
  };
  return (
    <DatePicker
      disabledDate={disabledDate}
      autoFocus
      defaultOpen
      format="MM/DD/YYYY"
      placeholder={placeholder}
      className="date-picker"
      onChange={handleChange}
      onBlur={() => setIsEditable(false)}
      value={value ? moment(value) : undefined}
      defaultValue={value ? moment(value) : undefined}
    />
  );
};

export default EditableDatePickerForm;
