import React from 'react';
import { TButtonDropdownProps } from './ButtonDropdown.type';
import DesktopDropdown from './components/DesktopDropdown';
import MobileDropdown from './components/MobileDropdown/MobileDropdown';

const ButtonDropdown: React.FC<TButtonDropdownProps> = ({
  isMobile,
  ...props
}) => {
  const {
    onClickItem,
    items,
    outlined,
    trigger,
    buttonTitle,
    buttonIcon,
    buttonType,
    children,
    iconColor,
    buttonDownIcon,
    buttonColor,
    buttonSize,
    buttonClassName,
  } = props;

  const dropdownProps = {
    onClickItem,
    items,
    trigger,
    buttonTitle,
    buttonIcon,
    buttonType,
    outlined,
    iconColor,
    buttonDownIcon,
    buttonColor,
    buttonSize,
    buttonClassName,
  };
  return (
    <>
      {isMobile ? (
        <MobileDropdown {...dropdownProps}>{children}</MobileDropdown>
      ) : (
        <DesktopDropdown {...dropdownProps}>{children}</DesktopDropdown>
      )}
    </>
  );
};

export { ButtonDropdown };
