import React, { FC, useEffect, useRef, useState } from 'react';
import { InputRef } from 'antd/es/input';
import { Button } from '../../Button';
import { TextArea } from '../../TextField';
import { TCommentEditingTextProps } from '../Comment.type';

const CommentEditingText: FC<TCommentEditingTextProps> = ({
  onSubmitEditText = () => {
    // do nothing
  },
  defaultValue,
  setEditItem,
  isUpdating,
}) => {
  const [text, setText] = useState(defaultValue);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="editing-wrapper mt-4">
      <TextArea
        className="customScroll"
        rows={3}
        defaultValue={defaultValue}
        placeholder=""
        ref={inputRef}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement & HTMLInputElement>
        ) => setText(e.target.value)}
      />
      <div className="button-wrapper mt-4">
        <Button
          type="primary"
          htmlType="button"
          onClick={() => onSubmitEditText(text)}
          size="small"
          loading={isUpdating}
        >
          Save
        </Button>
        <Button
          htmlType="button"
          size="small"
          className="ml-2"
          onClick={() => setEditItem('')}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CommentEditingText;
