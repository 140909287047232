import React from 'react';
import { Colors } from 'core/CssVariables';
import { Icon } from '../../../Icon';
import { toast } from '../../../Notification';
import { TextField } from '../../../TextField';
import { Form, FormItem } from '../../../Form';
import { TIconNames } from '../../../Icon/Icon.type';
import useForm from '../../../../hooks/useForm';
import { TEditableNumberFormProps } from '../EditableNumber.type';

const EditableNumberForm: React.FC<TEditableNumberFormProps> = ({
  placeholder,
  setIsEditable,
  numberValue,
  setValue,
  onEdit,
}) => {
  const { setCustomFieldValue, form } = useForm<{ value: string }>();

  const handleCustomField: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value;
    setCustomFieldValue('value', val);
    setValue(val);
  };

  const handlePressEnter = async () => {
    try {
      const formValues = await form.validateFields();
      if (form.isFieldTouched('value')) {
        onEdit(formValues.value);
      }
      setIsEditable(false);
    } catch (error) {
      toast({
        description: 'Please enter a valid number!',
        title: 'Updating failed',
        type: 'error',
      });
    }
  };

  return (
    <Form
      form={form}
      name="editableNumber"
      autoComplete="off"
      preserve={false}
      initialValues={{ value: numberValue }}
    >
      <FormItem name="value" className="my-0">
        <TextField
          autoFocus
          placeholder={placeholder}
          onChange={handleCustomField}
          onPressEnter={handlePressEnter}
          onBlur={handlePressEnter}
          className="no-border"
          type="number"
          suffix={
            <Icon icon={TIconNames.ENTER} color={Colors.LightGrey} size={15} />
          }
        />
      </FormItem>
    </Form>
  );
};

export default EditableNumberForm;
