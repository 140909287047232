import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={TIconNames.ARROW_DROPDOWN} size={16} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
