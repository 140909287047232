import React, { FC } from 'react';
import AntdForm from 'antd/es/form';
import FormItemAntd from 'antd/es/form/FormItem';
import FormListAntd from 'antd/es/form/FormList';
import { TFormItemProps, TFormListProps, TFormProps } from './Form.type';

const Form: FC<TFormProps> = (props) => {
  const { layout } = props;
  return <AntdForm layout={layout || 'vertical'} {...props} />;
};

const FormItem: FC<TFormItemProps> = (props) => {
  return <FormItemAntd {...props} />;
};

const FormList: FC<TFormListProps> = (props) => {
  return <FormListAntd {...props} />;
};

export { Form, FormItem, FormList };
