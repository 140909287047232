import styled, { css } from 'styled-components';
import { Row } from '../Grid';
import { TSTextIconWrapperProps } from './TextIcon.type';

export const STextIcon = styled(Row)`
  && {
    flex-direction: ${(props: TSTextIconWrapperProps) =>
      props.$revert ? 'row-reverse' : 'row'};
    .icon {
      ${({ $revert }) =>
        $revert
          ? css`
              margin-left: 8px;
            `
          : css`
              margin-right: 8px;
            `}
    }
  }
`;
