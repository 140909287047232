import React, { FC } from 'react';
import { TAvatarProps } from './Avatar.type';
import { SAvatar } from './Avatar.style';

const Avatar: FC<TAvatarProps> = ({ size = 32, active, ...props }) => {
  return (
    <SAvatar shape="circle" size={size} gap={4} $active={active} {...props} />
  );
};

export { Avatar };
