import styled from 'styled-components';

export const SVideoItemWrapper = styled.div`
  width: 203px;
  height: 119px;
  position: relative;

  .close-icon {
    position: absolute;
    right: -8px;
    top: -8px;
  }
`;
