import { TagProps } from 'antd/es/tag';
import { Colors, FontWeights } from 'core/CssVariables';
import { TFontLevel } from 'types/Font.types';

export type TTagProps = TagProps & {
  fontLevel?: TFontLevel;
  fontWeight?: FontWeights;
  color?: Colors;
  textColor?: Colors;
  positionedType?: EPositionedTagTypes;
  type?: ETagTypes;
  borderColor?: Colors;
  padding?: string;
};

export type TTagStyledProps = {
  color?: Colors;
  $type?: ETagTypes;
  $positionedType?: EPositionedTagTypes;
  $fontLevel?: TFontLevel;
  $fontWeight?: FontWeights;
  $textColor?: Colors;
  $borderColor?: Colors;
  $padding?: string;
};

export enum ETagTypes {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
}

export enum EPositionedTagTypes {
  CORNER_RIGHT = 'corner-right',
  CORNER_LEFT = 'corner-left',
  TOP = 'top',
}
