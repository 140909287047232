import React, { FC } from 'react';
import { Colors } from 'core/CssVariables';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';
import SEditableText from '../EditableText/EditableText.style';
import SUploadWrapper from '../../ImageUploader/ImageUploader.style';
import { TNonEditablePhotoProps } from '../EditableFields.type';

export const NonEditablePhoto: FC<TNonEditablePhotoProps> = ({
  photoUrl,
  width,
  height,
}) => {
  return (
    <SEditableText className="mt-2 mr-2 pr-6" canEdit={false}>
      <SUploadWrapper width={width} height={height}>
        <div className="upload-wrapper">
          <label htmlFor="image" className="upload-btn">
            {!photoUrl && (
              <Icon icon={TIconNames.UPLOAD} color={Colors.LightGrey} />
            )}
            <img
              className="upload-image"
              {...(photoUrl && { src: photoUrl })}
              alt="Upload"
            />
          </label>
        </div>
      </SUploadWrapper>
    </SEditableText>
  );
};

export default NonEditablePhoto;
