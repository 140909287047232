import React from 'react';
import { ClearIndicatorProps, components } from 'react-select';
import { Icon } from '../../Icon';
import { TIconNames } from '../../Icon/Icon.type';

const ClearIndicator = (clearIconProps: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...clearIconProps}>
      <Icon icon={TIconNames.CLOSE_ROUND_LIGHT} size={16} />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
