import styled, { css } from 'styled-components';
import { Colors } from 'core/CssVariables';

export const SCardTabMobile = styled.div<{ $active?: boolean }>`
  && {
    padding: 12px;
    background-color: ${Colors.White};
    ${({ $active }) =>
      $active &&
      css`
        background-color: ${Colors.BackgroundColor};

        .ant-avatar {
          background-color: ${Colors.Black};
          border-color: ${Colors.Black};

          .icon {
            path {
              fill: ${Colors.White};
            }
          }
        }
      `}
  }
`;

export default SCardTabMobile;
