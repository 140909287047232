import React, { FC } from 'react';
import { FontWeights } from 'core/CssVariables';
import { Text } from '../../../Typography';
import { TNoResultsWrapperProps } from './NoResultsWrapper.type';
import SNoResultsWrapper from './NoResultsWrapper.style';

export const NoResult: FC<TNoResultsWrapperProps> = ({
  noResult,
  text = 'No Results found',
}) => {
  if (!noResult) return null;
  return (
    <SNoResultsWrapper>
      {/* <Image src={img} alt="no result" width={260} /> */}
      <Text
        fontLevel={4}
        fontWeight={FontWeights.Medium}
        className="mt-4 no-result"
      >
        {text}
      </Text>
    </SNoResultsWrapper>
  );
};
