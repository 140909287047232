import React, { FC, useRef, useState } from 'react';
import {
  TOnChangeParam,
  TSelectOptionTypeUi,
  TSelectProps,
} from '../../Select.type';
import { SSelect } from '../../Select.style';
import MultiValueRemove from '../MultiValueRemove';
import ClearIndicator from '../ClearIndicator';
import DropdownIndicator from '../DropdownIndicator';
import Modal from './components/Modal';

const MobileSelect: FC<TSelectProps> = ({
  onChange = () => {},
  options,
  value,
  ...props
}) => {
  const [selectedValues, setSelectedValues] = useState<
    TSelectOptionTypeUi | TSelectOptionTypeUi[]
  >(value || []);
  const modalRef = useRef({ open: () => {} });
  const [modalIndex, setModalIndex] = useState(Date.now());

  const handleChange: TOnChangeParam = (val: any, action: any) => {
    setSelectedValues(val);
    onChange(val, action);
  };

  return (
    <>
      <SSelect
        onMenuOpen={() => modalRef.current.open()}
        components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
        menuIsOpen={false}
        hideSelectedOptions={false}
        options={options}
        onChange={handleChange}
        {...props}
        value={selectedValues}
        isSearchable={false}
      />
      <Modal
        onClose={() => setModalIndex(Date.now())}
        key={modalIndex}
        value={selectedValues}
        onSelect={(val) => handleChange(val, 'select-option')}
        options={options}
        ref={modalRef}
        {...props}
      />
    </>
  );
};

export default MobileSelect;
