import { FormInstance, useForm as AntdForm } from 'antd/es/form/Form';

export type TFromReturnType<T> = {
  setCustomFieldValue: (fieldName: string, customValue?: any) => void;
  form: FormInstance<T>;
};

const useForm = <T,>(initialValues?: FormInstance<T>): TFromReturnType<T> => {
  const [form] = AntdForm<T>(initialValues);
  const { setFieldsValue } = form;

  const setCustomFieldValue = (fieldName: string, customValue?: any): void => {
    // @ts-ignore
    setFieldsValue({ [fieldName]: customValue });
  };
  return { setCustomFieldValue, form };
};

export default useForm;
