import styled from 'styled-components';
import Modal from 'antd/es/modal';
import {
  Colors,
  FontSizes,
  FontWeights,
  Screens,
  Spacing,
} from 'core/CssVariables';
import { TModalProps } from './Modal.type';
import { LightTheme } from '../../theme';

export const SModal = styled(Modal)<TModalProps>`
  && {
    &.ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.7);
    }
    .ant-modal-content {
      background: ${LightTheme.modal.backgroundColor};
      border-radius: 8px;
      .ant-modal-header {
        background: transparent;
        padding: 16px 16px 8px;
        margin-top: ${Spacing.SpacingLG}px;
        .ant-modal-title {
          color: ${LightTheme.color};
          font-weight: ${FontWeights.Medium};
          font-size: ${FontSizes.FontXL}px;
          line-height: 28px;
          margin-right: 16px;
        }
      }
      .ant-modal-body {
        padding: 24px;
        max-height: 65vh;
        overflow: auto;

        @media (min-width: ${Screens.ScreensMD}) {
          max-height: 75vh;
          width: calc(100% - 16px);
          padding: 24px 16px 24px 24px;
        }
      }
      .ant-modal-close-x {
        svg {
          fill: ${Colors.BackgroundColor};
        }
      }
      .ant-modal-footer {
        display: flex;
        text-align: left;
        padding: 8px 16px 16px;

        .ant-btn {
          flex: 1;
          color: ${Colors.Black};
          font-weight: ${FontWeights.Medium};
          border: none;

          &.ant-btn-default {
            background-color: ${Colors.DisabledColor};
          }
        }

        @media (min-width: ${Screens.ScreensMD}) {
          display: block;
        }
      }
    }
  }
`;

export const SDeleteWrapperMessage = styled.p`
  && {
    font-size: 14px;
    text-align: left;
    color: ${LightTheme.color};
  }
`;
