import { ReactNode } from 'react';
import { SwitchProps } from 'antd/es/switch';
import { Colors } from 'core/CssVariables';

export enum TSwitchTypes {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Danger = 'danger',
}

export type TSwitchColors = { [key in TSwitchTypes]: Colors };
export type TSwitch = {
  color?: TSwitchTypes;
  light?: boolean;
  disabled?: boolean;
  label: string | ReactNode;
} & SwitchProps;

export type TSwitchStyled = {
  color?: TSwitchTypes;
  light?: boolean;
  disabled?: boolean;
} & SwitchProps;

export interface SwitchCompoundedComponent
  extends React.ForwardRefExoticComponent<
    TSwitchStyled & React.RefAttributes<HTMLElement>
  > {
  __ANT_SWITCH: boolean;
}
