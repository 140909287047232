import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import { Colors } from 'core/CssVariables';
import { TTabsStyledProps } from './Tabs.type';

export const { TabPane } = Tabs;

export const STabs = styled(Tabs)<TTabsStyledProps>`
  && {
    &.ant-tabs {
      & .ant-tabs-nav {
        background-color: ${Colors.Transparent};

        :before {
          border-bottom: none;
        }
      }

      & .ant-tabs-nav-list {
        & .ant-tabs-tab {
          background-color: ${Colors.Transparent};

          & .ant-tabs-tab-btn {
            &,
            &:focus,
            &:active {
              color: ${Colors.DarkGrey};
            }
          }

          &.ant-tabs-tab-active {
            background-color: ${Colors.Transparent};
            border-bottom-color: ${Colors.WarningColor};

            & .ant-tabs-tab-btn {
              &,
              &:focus,
              &:active,
              & .ant-typography {
                color: ${Colors.WarningColor};
              }
            }
          }

          &:hover {
            color: ${Colors.Black};
          }
        }
      }
    }
  }
`;
