import { FC } from 'react';
import styled, { css } from 'styled-components';
import Checkbox from 'antd/es/checkbox';

import { Colors, FontWeights } from 'core/CssVariables';
import { LightTheme } from '../../theme';
import { TCheckboxProps } from './Checbox.type';

const SCheckbox = styled<FC<TCheckboxProps>>(Checkbox)`
  & .ant-checkbox-inner {
    border-radius: 2px;
    border-color: ${() => LightTheme.table.checkboxBorderColor};
    border-width: 1px;
    background-color: transparent;
  }
  &:hover .ant-checkbox-inner,
  &:focus {
    .ant-checkbox-inner {
      border-color: ${Colors.PrimaryColor};
    }
  }
  .ant-checkbox-checked {
    &::after {
      border-color: ${Colors.PrimaryColor};
    }

    .ant-checkbox-inner {
      background-color: ${Colors.PrimaryColor};
      border-color: ${Colors.PrimaryColor};
    }
  }
  .ant-checkbox {
    + span {
      color: ${() => LightTheme.table.checkboxLabelColor};
      font-weight: ${FontWeights.Regular};
    }
  }
  ${({ lineThrough, checked }) =>
    lineThrough &&
    checked &&
    css`
      .ant-checkbox {
        + span {
          text-decoration-line: line-through;
          color: ${Colors.Blue};
          font-weight: ${FontWeights.Regular};
        }
      }
    `}
`;
export default SCheckbox;
