import styled from 'styled-components';
import { FontSizes, Screens } from 'core/CssVariables';
import { TextIcon } from '../../../TextIcon';

const SBackButton = styled(TextIcon)`
  && {
    display: flex;
    margin-bottom: 4px;
    font-size: ${FontSizes.FontMD};
    color: ${({ theme }) => theme.backButton};
    .icon {
      path {
        fill: ${({ theme }) => theme.backButton} !important;
      }
    }
    @media (max-width: ${Screens.ScreensMD}) {
      display: none;
    }
  }
`;

export default SBackButton;
