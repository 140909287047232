import React, { FC } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Link } from 'react-router-dom';

import { TTextLinkProps, ETextLinkColors } from './TextLink.type';
import { SText } from './TextLink.style';

const TextLink: FC<TTextLinkProps> = ({
  children,
  linkText,
  to = '',
  state = {},
  type,
  className,
  fontLevel = 4,
  fontWeight = FontWeights.Regular,
  onClick = () => {
    /* do nothing */
  },
}) => {
  return (
    <SText
      className={className}
      fontLevel={fontLevel}
      color={Colors[type]}
      fontWeight={fontWeight}
    >
      {children}
      <Link className="ml-2" to={to} state={state} onClick={onClick}>
        {linkText}
      </Link>
    </SText>
  );
};

export { TextLink, ETextLinkColors };
