import { RefObject, useEffect } from 'react';

const useResize = <T,>(
    myRef: RefObject<T> | null,
    onResize: (myRef: RefObject<T>) => void,
) => {
    const handleResize = () => {
        if (myRef) onResize(myRef);
    };

    useEffect(() => {
        handleResize();
        if (myRef?.current) {
            window.addEventListener('resize', handleResize);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [myRef]);
};

export { useResize };
