import React, { FC } from 'react';
import { TTabsProps } from './Tabs.type';
import { STabs, TabPane } from './Tabs.style';

const Tabs: FC<TTabsProps> = ({
  tabData,
  onChange,
  type = 'card',
  ...props
}) => {
  return (
    <STabs onChange={onChange} type={type} tabBarGutter={0} {...props}>
      {tabData.map((item) => (
        <TabPane tab={item.tab} key={item.key}>
          {item.content}
        </TabPane>
      ))}
    </STabs>
  );
};

export { Tabs };
