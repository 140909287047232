import React from 'react';
import { Colors } from 'core/CssVariables';
import { Icon } from '../Icon';
import { TIconNames } from '../Icon/Icon.type';
import { SDatePicker, SDateRangePicker } from './DatePicker.style';
import { TDatePickerProps, TDateRangePickerProps } from './DatePicker.type';

const DateRangePicker: React.FC<TDateRangePickerProps> = ({
  format = 'MM/DD/YYYY',
  ...props
}) => {
  return (
    <SDateRangePicker
      suffixIcon={
        <Icon
          icon={TIconNames.CALENDAR_ICON}
          size={12}
          color={Colors.DarkGrey}
        />
      }
      format={format}
      {...props}
    />
  );
};

const DatePicker: React.FC<TDatePickerProps> = ({
  format = 'MM/DD/YYYY',
  ...props
}) => {
  return (
    <SDatePicker
      showNow={false}
      suffixIcon={
        <Icon
          icon={TIconNames.CALENDAR_ICON}
          size={12}
          color={Colors.DarkGrey}
        />
      }
      format={format}
      {...props}
    />
  );
};

export { DateRangePicker, DatePicker };
