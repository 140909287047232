import React, { FC } from 'react';
import AntRadio from 'antd/es/radio';
import { Text } from '../Typography';
import SRadio from './Radio.style';
import { TRadioProps } from './Radio.type';

const RadioGroup = AntRadio.Group;
const Radio: FC<TRadioProps> = (props) => {
  const { label, ...other } = props;

  return (
    <SRadio {...other}>
      {typeof label === 'string' ? (
        <Text className="radio-label" fontLevel={5}>
          {label}
        </Text>
      ) : (
        label
      )}
    </SRadio>
  );
};

export { RadioGroup, Radio };
