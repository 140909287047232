import styled from 'styled-components';
import Spin from 'antd/es/spin';
import { Row } from '../Grid';
import { TSpinnerProps } from './Spinner.type';

export const SPageSpinnerWrapper = styled(Row)`
  height: 100%;
`;

const spinnerSizes = {
  small: 12,
  large: 44,
  default: 14,
};

export const SSpinner = styled(Spin)<TSpinnerProps>`
  .anticon-loading {
    font-size: ${({ size, width }) =>
      (width && width) || (size ? spinnerSizes[size] : 14)}px !important;
  }

  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
