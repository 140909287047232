import React, { forwardRef } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { InputRef } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { Icon } from '../Icon';
import { TIconNames } from '../Icon/Icon.type';

import {
  SGroup,
  SInput,
  SSearchInput,
  SPassword,
  STextArea,
} from './TextField.style';
import {
  TGroupProps,
  TInputProps,
  TPasswordProps,
  TTextAreaProps,
} from './TextField.type';
import { Colors } from 'core/CssVariables';

export const TextField = forwardRef<InputRef, TInputProps>(
  (props: TInputProps, ref) => {
    return <SInput ref={ref} {...props} />;
  }
);

export const SearchInput = forwardRef<InputRef, TInputProps>(
  (props: TInputProps, ref) => {
    return (
      <SSearchInput
        ref={ref}
        {...props}
        prefix={<Icon icon={TIconNames.SEARCH} color={Colors.DarkGrey} />}
      />
    );
  }
);

export const Password = (props: TPasswordProps) => {
  return (
    <SPassword
      iconRender={(visible: boolean) =>
        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
      }
      {...props}
    />
  );
};

export const TextArea = forwardRef<TextAreaRef, TTextAreaProps>(
  (props, ref) => {
    return <STextArea ref={ref} {...props} />;
  }
);

export const Group = (props: TGroupProps) => {
  return <SGroup {...props} />;
};
