import React, { FC } from 'react';
import { TImage } from './Image.types';
import SImage from './Image.styles';

const Image: FC<TImage> = ({
  width,
  height,
  src,
  alt,
  onClick,
  className,
  shape,
}) => {
  return (
    <SImage
      shape={shape}
      role="presentation"
      src={src}
      className={className}
      width={width}
      height={height}
      alt={alt}
      onClick={onClick}
    />
  );
};

export { Image };
