import React, { FC } from 'react';

import { Colors, FontWeights } from 'core/CssVariables';
import { Icon } from '../Icon';
import { TIconNames } from '../Icon/Icon.type';
import { Text } from '../Typography';
import { SDeleteWrapperMessage, SModal } from './Modal.style';
import { TModalDeleteProps, TModalProps } from './Modal.type';

const Modal: FC<Omit<TModalProps, 'width'>> = (props) => {
  const { okText, size, centered = true } = props;
  return (
    <SModal
      centered={centered}
      closeIcon={<Icon icon={TIconNames.CLOSE_CIRCLE} />}
      okText={okText || 'Save'}
      width={size}
      {...props}
    />
  );
};

const ModalDeleteConfirmation: FC<Omit<TModalDeleteProps, 'width'>> = (
  props
) => {
  const { title, onCancel, onOk, warningText, subtitleText, size } = props;
  const onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (onCancel) {
      onCancel(e);
    }
  };
  const onSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (onOk) {
      onOk(e);
    }
  };
  return (
    <SModal
      centered
      closeIcon={<Icon icon={TIconNames.CLOSE_CIRCLE} />}
      width={size}
      {...props}
      onCancel={onClose}
      onOk={onSubmit}
    >
      <SDeleteWrapperMessage>
        {subtitleText ||
          `Are you sure you want to ${title?.toLocaleString().toLowerCase()}?`}
      </SDeleteWrapperMessage>
      {warningText && (
        <SDeleteWrapperMessage className="pt-2">
          <Text
            fontLevel={5}
            fontWeight={FontWeights.SemiBold}
            color={Colors.WarningColor}
          >
            Warning:&nbsp;
          </Text>
          {warningText}
        </SDeleteWrapperMessage>
      )}
    </SModal>
  );
};

export { Modal, ModalDeleteConfirmation };
