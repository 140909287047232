import React, { FC } from 'react';
import { Button } from '../../Button';
import { EButtonColors } from '../../Button/Button.type';
import { TCommentFooterProps } from '../Comment.type';

const CommentFooter: FC<TCommentFooterProps> = ({
  onEdit = () => {
    // do nothing
  },
  onDelete = () => {
    // do nothing
  },
  commentId,
  canEdit,
}) => {
  if (!canEdit) {
    return null;
  }

  return (
    <div className="button-wrapper mt-2">
      <Button
        type="link"
        htmlType="button"
        color={EButtonColors.Primary}
        onClick={() => onEdit(commentId)}
        size="small"
      >
        Edit
      </Button>
      <Button
        type="link"
        htmlType="button"
        color={EButtonColors.Primary}
        size="small"
        className="ml-2"
        onClick={() => onDelete(commentId)}
      >
        Delete
      </Button>
    </div>
  );
};
export default CommentFooter;
