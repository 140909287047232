import React, { FC } from 'react';

export const CardTabsFooter: FC<{ renderFooter: boolean,  children?: React.ReactNode }> = ({
  children,
  renderFooter,
}) => {
  if (!renderFooter) {
    return null;
  }
  return <div className="card-tabs-footer mt-6">{children}</div>;
};

export default CardTabsFooter;
