import React, { FC, useState } from 'react';
import { Col, Row } from '../../Grid';
import { RadioGroupSwitcher } from '../../RadioGroupSwitcher';
import { TEditableRadioGroupSwitcher } from './EditableRadioGroupSwitcher.types';

const EditableRadioGroupSwitcher: FC<TEditableRadioGroupSwitcher> = ({
  onClick,
  value: defaultValue,
  canEdit,
  options,
}) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (val: boolean | string) => {
    setValue(val);
    onClick(val);
  };

  return (
    <Row gutter={[4, 4]} className="py-1">
      <Col span={24}>
        <RadioGroupSwitcher
          options={options}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={!canEdit}
        />
      </Col>
    </Row>
  );
};
export { EditableRadioGroupSwitcher };
