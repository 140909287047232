import { To } from 'history';
import { FontWeights } from 'core/CssVariables';
import { TLevel } from 'types/Font.types';

export enum ETextLinkColors {
  PRIMARY_COLOR = 'PrimaryColor',
  DISABLED_COLOR = 'DisabledColor',
  BLACK = 'Black',
  LIGHT_GREY = 'LightGrey',
  ERROR_COLOR = 'ErrorColor',
  SUCCESS_COLOR = 'SuccessColor',
}

export type TTextLinkProps = {
  children?: string | JSX.Element;
  linkText: string;
  onClick?: () => void;
  to?: To;
  state?: { [key: string]: boolean | number | string };
  className?: string;
  type: ETextLinkColors;
  fontLevel?: TLevel;
  fontWeight?: FontWeights;
};
