import React, { ChangeEventHandler, FC } from 'react';
import useForm from '../../../../hooks/useForm';
import { Form, FormItem } from '../../../Form';
import { TextArea } from '../../../TextField';
import { TEditableTextAreaFormProps } from '../EditableTextArea.type';

const EditableTextAreaForm: FC<TEditableTextAreaFormProps> = ({
  text,
  placeholder,
  onEdit,
  setValue,
  setIsEditable,
  required,
}) => {
  const { setCustomFieldValue, form } = useForm<{ text: string }>();

  const onChange: ChangeEventHandler<HTMLInputElement & HTMLTextAreaElement> = (
    e
  ) => {
    const val = e.target.value;
    setCustomFieldValue('text', val);
    setValue(val);
  };

  const onBlur = async () => {
    try {
      const formValues = await form.validateFields();
      if (form.isFieldTouched('text')) {
        onEdit(formValues.text);
      }
      setIsEditable(false);
    } catch (e) {
      // do nothing
    }
  };

  return (
    <Form
      form={form}
      name="editableTextArea"
      autoComplete="off"
      preserve={false}
      initialValues={{ text }}
    >
      <FormItem
        name="text"
        className="my-0"
        rules={
          required
            ? [{ required: true, message: 'This field is required' }]
            : undefined
        }
      >
        <TextArea
          autoFocus
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          className="text-area"
        />
      </FormItem>
    </Form>
  );
};

export default EditableTextAreaForm;
