import styled from 'styled-components';

export const SImageItemWrapper = styled.div`
  position: relative;
  img {
    object-fit: cover;
    width: 203px;
    height: 119px;
  }
  .close-icon {
    position: absolute;
    right: -8px;
    top: -8px;
  }
`;
