import React, { FC } from 'react';
import { STimeline, STimelineItem } from './Timeline.styles';
import { TTimelineProps } from './Timeline.types';

const Timeline: FC<TTimelineProps> = ({ data, ...props }) => {
  return (
    <STimeline {...props}>
      {data.map((item) => (
        <STimelineItem key={item.id} {...item} />
      ))}
    </STimeline>
  );
};

export { Timeline };
