import { FC } from 'react';
import { EFileTypeUI } from '../../types/generalTypes';
import { DocumentItem } from './components/DocumentItem';
import { ImageItem } from './components/ImageItem';
import { VideoItem } from './components/VideoItem';
import { TUploadedFileProps } from './Uploader.type';


export const fileTypesMapper: { [key in EFileTypeUI]: string[] } = {
  [EFileTypeUI.PHOTO]: [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/bmp',
    'image/jpg',
    'image/avif',
    'image/svg+xml',
    'image/tif',
    'image/tiff',
    'image/webp',
  ],
  [EFileTypeUI.VIDEO]: ['video/mp4', 'video/mpeg', 'video/ogv', 'video/ts', 'video/webm', 'video/MP4'],
  [EFileTypeUI.OTHER]: [],
};

export const uploadItemByFileType: { [key in EFileTypeUI]: FC<TUploadedFileProps> } = {
  [EFileTypeUI.PHOTO]: ImageItem,
  [EFileTypeUI.VIDEO]: VideoItem,
  [EFileTypeUI.OTHER]: DocumentItem,
};
