import { Colors, Screens } from 'core/CssVariables';
import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import { TTabsStyledProps } from '../Tabs/Tabs.type';

export const SCardTabs = styled(Tabs)<TTabsStyledProps>`
  && {
    &.ant-tabs {
      .ant-tabs-content {
        position: relative;
        height: calc(100vh - 339px);
        overflow: hidden auto;
        padding-right: 8px;
      }
      &-top {
        .ant-tabs-nav {
          margin: 0 0 ${(props) => (props.$withPage ? 0 : '24px')} 0;
          height: 48px;
        }
      }
      &-left {
        .ant-tabs-nav {
          height: calc(100vh - 339px);
        }
      }
      &.ant-tabs-left {
        .ant-tabs-content-holder {
          border-left: 0;
        }
      }
      & .ant-tabs-nav {
        background-color: ${Colors.Transparent};
        :before {
          border-bottom: none;
        }

        .ant-tabs-nav-operations {
          .ant-tabs-nav-more {
            display: none;
          }
        }
      }

      & .ant-tabs-nav-list {
        & .ant-tabs-tab {
          background-color: ${Colors.Transparent};
          text-align: left;
          padding: 0;
          border: 0;
          & .ant-tabs-tab-btn {
            &,
            &:focus,
            &:active {
              color: ${Colors.DarkGrey};
            }
          }

          &.ant-tabs-tab-disabled {
            &:hover {
              cursor: default;
            }
          }
          &.ant-tabs-tab-active {
            background-color: ${Colors.Transparent};
            .ant-tag.tag {
              color: ${Colors.White};
            }

            & .ant-tabs-tab-btn {
              &,
              &:focus,
              &:active,
              & {
                text-shadow: none;
              }
            }
          }

          &:hover {
            color: ${Colors.Black};
          }
        }
      }
      .card-tabs-footer {
        margin-top: 24px;
      }
      @media (max-width: ${Screens.ScreensMD}) {
        position: absolute;
        top: 64px;
        left: 0;
        width: 100%;
        .card-tabs-footer {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 9;
          background-color: ${Colors.White};
          box-shadow: 0 0 16px ${Colors.Black}21;
          border-radius: 8px 8px 0 0;
          padding: 0 8px 8px;

          .ant-divider {
            display: none;
          }
        }
        .ant-tabs-content {
          padding: 18px;
          position: relative;
          height: calc(100vh - 192px);
          overflow: hidden auto;
        }
        & .ant-tabs-nav {
          padding-bottom: 36px;
        }
        & .ant-tabs-nav-wrap {
          position: fixed;
          z-index: 9;
          width: 100%;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
          border-top: 1px solid ${Colors.DisabledColor};
          border-bottom: 1px solid ${Colors.DisabledColor};
        }
        & .ant-tabs-nav-list {
          & .ant-tabs-tab {
            border-radius: 0;
            border-left: 1px solid ${Colors.DisabledColor};
            border-right: 1px solid ${Colors.DisabledColor};
            & .ant-tabs-tab-btn {
              &,
              &:focus,
              &:active {
                color: ${Colors.DarkGrey};
              }
            }
          }
        }
      }
    }
  }
`;
