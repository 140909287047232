import React, { FC, useEffect, useState } from 'react';
import { EmptyText } from '../EmptyText';
import { NonEditableText } from '../NonEditableText';
import EditableFieldWrapper from '../EditableFieldWrapper';
import { TEditableSelectProps } from '../EditableFields.type';
import EditableSelectForm from './components/EditableSelectForm';
import EditableSelectText from './components/EditableSelectText';

const EditableSelect: FC<TEditableSelectProps> = (props) => {
  const {
    fontLevel,
    onEdit,
    isLoading,
    value: defaultValue,
    error,
    icon,
    minMedia,
    fontWeight,
    placeholder,
    options,
    color,
    emptyText,
    isCreatable,
    canRemove,
    canEdit,
    showTextValue,
    ...rest
  } = props;
  const [value, setValue] = useState(defaultValue);
  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    if (error) setValue(defaultValue);
  }, [error]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (canEdit) {
    return (
      <EditableFieldWrapper
        canEdit={canEdit}
        isEditable={isEditable}
        icon={icon}
        color={color}
        minMedia={minMedia}
        isLoading={isLoading}
        error={error}
      >
        {isEditable ? (
          <EditableSelectForm
            isClearable={canRemove}
            isCreatable={isCreatable}
            onEdit={onEdit}
            placeholder={placeholder}
            setIsEditable={setIsEditable}
            value={value}
            options={options}
            {...rest}
          />
        ) : (
          <>
            {value?.value ? (
              <EditableSelectText
                setIsEditable={setIsEditable}
                showTextValue={showTextValue}
                value={value}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
                color={color}
              />
            ) : (
              <EmptyText
                emptyText={emptyText}
                fontWeight={fontWeight}
                fontLevel={fontLevel}
                onClick={() => setIsEditable(true)}
              />
            )}
          </>
        )}
      </EditableFieldWrapper>
    );
  }
  return (
    <NonEditableText
      value={value?.label || ''}
      icon={icon}
      fontWeight={fontWeight}
      fontLevel={fontLevel}
    />
  );
};

export { EditableSelect };
