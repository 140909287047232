import Switch, { SwitchProps } from 'antd/es/switch';
import styled from 'styled-components';
import { Colors } from 'core/CssVariables';
import {
  TSwitchColors,
  TSwitchTypes,
  SwitchCompoundedComponent,
} from './Switch.types';
import { LightTheme } from '../../theme';

const switchColors: TSwitchColors = {
  [TSwitchTypes.Success]: Colors.SuccessColor,
  [TSwitchTypes.Warning]: Colors.WarningColor,
  [TSwitchTypes.Danger]: Colors.ErrorColor,
  [TSwitchTypes.Info]: Colors.Blue,
};

const SSwitch = styled<SwitchCompoundedComponent>(Switch)`
  && {
    // background-color: ${({ color }) =>
      color ? switchColors[color] : Colors.PrimaryColor};
    .ant-switch-handle {
      &:before {
        background-color: ${({ light }) =>
          light ? Colors.White : LightTheme.switch.handle};
      }
    }

    &.ant-switch-checked:focus {
      box-shadow: none;
    }
  }
`;

export default SSwitch;
